import { ChangeEvent, FC } from 'react';
import { useDispatch } from 'react-redux';

import { Accordion, ToggleSwitch } from 'components';
import { ActionWithStatus } from 'core/types/action';
import { setActionsCreate } from 'features/Actions';

import styles from './AccessSwitchForm.module.scss';
import { sortActions } from './utils';

interface AccessSwitchFormProps {
  actionsNotSorted: ActionWithStatus[];
}

export const AccessSwitchForm: FC<AccessSwitchFormProps> = ({
  actionsNotSorted,
}) => {
  const dispatch = useDispatch();

  const actionsForRender = sortActions(actionsNotSorted);

  const handleSwitch = (id: string, checked: boolean) => {
    dispatch(
      setActionsCreate(
        actionsNotSorted.map((action) => {
          if (action.id === id) {
            return {
              ...action,
              status: checked,
            };
          }
          return { ...action };
        })
      )
    );
  };

  const handleSwitchScreen = (id: string, checked: boolean) => {
    dispatch(
      setActionsCreate(
        actionsNotSorted.map((action) => {
          if (action.accessCategory.id === id) {
            return {
              ...action,
              status: checked,
            };
          }
          return { ...action };
        })
      )
    );
  };

  const renderElements = Object.keys(actionsForRender).map((item) => {
    const categoryTitle = actionsForRender[item][0]?.accessCategory.title || '';
    const canEditScreen = actionsForRender[item].reduce(
      (prev, elem) => prev || elem.status,
      false
    );

    const onChangeCategory = (event: ChangeEvent<HTMLInputElement>) => {
      handleSwitchScreen(item, event.target.checked);
    };

    return (
      <div className={styles.accessEdit__accordionWrapper}>
        <Accordion
          title={categoryTitle}
          contentClassName={styles.accessEdit__accordionContent}
          headerContent={
            <ToggleSwitch
              checked={canEditScreen}
              onChange={onChangeCategory}
              id={item}
            />
          }
          key={item}
        >
          {actionsForRender[item].map((elem) => {
            const actionChecked = elem.status;

            const onChangeAction = (event: ChangeEvent<HTMLInputElement>) => {
              handleSwitch(elem.id, event.target.checked);
            };

            return (
              <div className={styles.accessEdit__accordionItem} key={elem.id}>
                {elem.title}
                <ToggleSwitch
                  checked={actionChecked}
                  onChange={onChangeAction}
                  id={elem.id}
                  disabled={!canEditScreen}
                />
              </div>
            );
          })}
        </Accordion>
      </div>
    );
  });

  return <>{renderElements}</>;
};
