import { ChangeEvent, FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  Checkbox,
  getTitleFromValueType,
  getValueFromValueType,
  Input,
  RadioSlide,
  Select,
} from 'components';
import { Create } from 'core/modals';
import { OrganizationType } from 'core/types';
import { getIsClient, getUserOrganization } from 'features/Auth';
import { OrganizationSelect } from 'features/Organizations';
import {
  fetchSystemsOrganizationRequest,
  getSystemsOrganizationSelectList,
} from 'features/Systems';

import {
  CUSTOM_FIELD_ACCESS_OPTIONS,
  DEFAULT_CUSTOM_FIELDS_FORM_VALUES,
  SLIDE_RADIO_TABS,
} from '../../constants';
import {
  checkIsExistRequest,
  checkIsExistSuccess,
  createCustomField,
  updateCustomField,
} from '../../ducks/actions';
import { getCurrentCustomField } from '../../ducks/selectors';
import { CreateCustomFieldData } from '../../types';

import styles from './CustomFieldsCreate.module.scss';

interface CustomFieldsCreateProps {
  isModal: boolean;
  toggleModal(): void;
  isEditMode?: boolean;
}

export const CustomFieldsCreate: FC<CustomFieldsCreateProps> = ({
  isModal,
  toggleModal,
  isEditMode,
}) => {
  const dispatch = useDispatch();

  const systemSelectList = useSelector(getSystemsOrganizationSelectList);
  const organization = useSelector(getUserOrganization);
  const isClient = useSelector(getIsClient);
  const customField = useSelector(getCurrentCustomField);
  const customFilterOrganization = {
    organizationTypes: {
      value: OrganizationType.CUSTOMER,
      title: 'Клиентская',
    },
  };
  const canEdit = !!customField && isEditMode;

  const closeModal = () => {
    toggleModal();
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    setValue,
    resetField,
    control,
    watch,
  } = useForm<CreateCustomFieldData>({
    mode: 'onChange',
    defaultValues: DEFAULT_CUSTOM_FIELDS_FORM_VALUES,
  });

  const { organizationId, accessType } = watch();

  const isCustomer = accessType === OrganizationType.CUSTOMER;

  const getSubmitDisabled = () => {
    if (isEditMode) {
      return false;
    }
    return !isValid;
  };

  const nameInputOptions = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название кастомного поля не может быть длиннее 100 символов.',
    },
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      const title = e.target.value;
      if (title) {
        dispatch(checkIsExistRequest(title));
      }
    },
  });

  const formSubmitHandler = handleSubmit((data) => {
    const organizationValue = getValueFromValueType(data?.organizationId);
    const systemValue = {
      id: getValueFromValueType(data.systemId),
      title: getTitleFromValueType(data.systemId),
    };
    const createData = {
      ...data,
      organization: organizationValue ? { id: organizationValue } : undefined,
      system: systemValue,
    };
    delete createData.systemId;
    delete createData.organizationId;

    dispatch(createCustomField(createData));
    closeModal();
    reset();
  });

  const formEditCustomFieldSubmit = handleSubmit((data) => {
    if (customField) {
      const editData = {
        ...customField,
        ...data,
      };
      delete editData.organizationId;
      delete editData.systemId;
      dispatch(updateCustomField(editData));
    }
    closeModal();
    reset();
  });

  const getInitialOrganizationOption = () => {
    if (!isEditMode && isClient) {
      return {
        title: organization?.organizationTitle || '',
        value: organization?.organizationId || '',
      };
    }
    if (canEdit) {
      return {
        title: customField.organization?.title || '',
        value: customField.organization?.id || '',
      };
    }
    return null;
  };

  const getInitialSystemOption = () => {
    if (canEdit) {
      return {
        title: customField.system?.title || '',
        value: customField.system?.id || '',
      };
    }
    return null;
  };

  useEffect(() => {
    if (organizationId && !Array.isArray(organizationId) && !isEditMode) {
      resetField('systemId');
      dispatch(fetchSystemsOrganizationRequest(organizationId.value));
    }
  }, [organizationId]);

  useEffect(() => {
    return () => {
      dispatch(checkIsExistSuccess(false));
    };
  }, []);

  useEffect(() => {
    if (customField && isEditMode) {
      setValue('title', customField.title);
      setValue('customFieldType', customField.customFieldType);
      setValue('systemId', getInitialSystemOption());
      setValue('accessType', customField?.accessType);
      setValue('organizationId', getInitialOrganizationOption());
      setValue('required', customField.required);
    }
  }, [customField, isEditMode]);

  return (
    <Create
      toggleModal={toggleModal}
      isModal={isModal}
      title={`${canEdit ? 'Редактировать' : 'Создать'} кастомное поле`}
      onSubmit={canEdit ? formEditCustomFieldSubmit : formSubmitHandler}
      createTitle={isEditMode ? 'Сохранить' : 'Создать'}
      disabledSubmit={getSubmitDisabled()}
      subModalText={`${
        canEdit ? 'редактирование' : 'создание'
      } кастомного поля`}
    >
      <div className={styles.customFieldsCreate__form}>
        <Controller
          control={control}
          name="accessType"
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <RadioSlide
                items={CUSTOM_FIELD_ACCESS_OPTIONS}
                value={field.value}
                onChange={field.onChange}
                disabled={isClient || canEdit || isEditMode}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="customFieldType"
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <RadioSlide
                items={SLIDE_RADIO_TABS}
                value={field.value}
                onChange={field.onChange}
                disabled={isClient || canEdit || isEditMode}
              />
            );
          }}
        />
        <Input
          {...nameInputOptions}
          label="Название"
          error={!!errors.title}
          errorMessage={errors.title?.message}
        />
        <Controller
          control={control}
          name="organizationId"
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <OrganizationSelect
                onChange={field.onChange}
                placeholder="Организация"
                value={field?.value}
                addOrgState={false}
                disabled={isClient || canEdit}
                customFilters={customFilterOrganization}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="systemId"
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <Select<string>
                label="Система"
                mobileModalTitle="систему"
                onChange={field.onChange}
                value={field.value}
                options={systemSelectList}
                disabled={isClient || canEdit}
              />
            );
          }}
        />
        {isCustomer && (
          <Controller
            control={control}
            name="required"
            render={({ field }) => {
              return (
                <Checkbox
                  label="Сделать поле обязательным"
                  checked={field.value}
                  onChange={field.onChange}
                />
              );
            }}
          />
        )}
      </div>
    </Create>
  );
};
