import { getUrl } from 'utils';

export const endpoints = {
  getTickets: () => getUrl('ticket/filter'),
  getTicket: (id: string) => getUrl(`ticket/${id}`),
  setTicketToWork: () => getUrl('ticket/work'),
  addSpecialist: () => getUrl('ticket/appoint'),
  getTicketsCount: () => getUrl('ticket/filter/count'),
  getCurrentTicketActions: () => getUrl('action/action-auth-user'),
  deleteTicket: (id: string) => getUrl(`ticket/${id}`),
  getCurrentTicketState: () =>
    'ticket-report/api/v1/report/organization/current-state',
  getHistoryTicketState: () =>
    'ticket-report/api/v1/report/organization/history',
  getWorkGroupTicketState: () =>
    'ticket-report/api/v1/report/organization/workgroup',
  getStatuses: () => getUrl('ticket/filter-params/statuses'),
  getFiltersData: () => getUrl(`filters`),
  createFilter: () => getUrl('filters'),
  deleteFilter: (id: string) => getUrl(`filters/${id}`),
  setFilterTitle: (id: string) => getUrl(`filters/${id}/title`),
  getTicketsTableConfig: () => getUrl('ticket-table-config'),
  getActualTicketsTableConfig: () => getUrl('ticket-table-config/actual'),
  createTicketsTableConfig: () => getUrl('ticket-table-config'),
  getTicketTypes: () => getUrl('type/accessible-types'),
};
