import { RenderTypes, TableColumns } from 'components';
import { entityNames } from 'core/constants';
import { Priority } from 'core/types';
import { TableTicketsTypes } from 'core/types/ticket';

import {
  fetchTicketStatusJoinSuccess,
  fetchTicketStatusUpdateSuccess,
  fetchTicketTypesJoinSuccess,
  fetchTicketTypesUpdateSuccess,
} from './ducks/actions';

export const TICKETS_BY_CONTRACT_IDS_TABLE_FIELDS: TableColumns = [
  {
    title: 'ID',
    sortField: 'number',
    fieldName: 'number',
  },
  {
    title: 'Приоритет',
    sortField: 'priority',
    fieldName: 'priority',
  },
  { title: 'Тема', fieldName: 'theme' },
];

export const TICKETS_FROM_DESKTOP_TABLE_FIELDS: TableColumns = [
  {
    title: 'Тикет',
    sortField: 'number',
    fieldName: 'number',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  {
    title: 'SLA',
    fieldName: 'sla',
  },
  { title: 'Исполнитель', fieldName: 'specialistId', sortField: 'specialist' },
];

export const TICKETS_FROM_DESKTOP_HISTORY_TABLE_FIELDS: TableColumns = [
  {
    title: 'Тикет',
    sortField: 'number',
    fieldName: 'number',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  { title: 'SLA', fieldName: 'sla' },
  { title: 'Тема', fieldName: 'theme' },
  {
    title: 'Исполнитель',
    fieldName: 'specialistId',
    sortField: 'specialist',
  },
  {
    title: 'Статус',
    fieldName: 'status',
    sortField: 'status',
  },
];

export const DEFAULT_TICKETS_FILTER_VALUES = {
  theme: '',
  number: '',
  tagName: '',
  statuses: [],
  priorities: [],
  systemIds: [],
  typeIds: [],
  clientIds: [],
  specialistIds: [],
  dateSolve: '',
  dateCreate: '',
  customFields: [],
};

export const PRIORITY_OPTIONS = [
  { title: 'Без приоритета', value: Priority.NONE },
  { title: 'Низкий', value: Priority.LOW },
  { title: 'Средний', value: Priority.MIDDLE },
  { title: 'Высокий', value: Priority.HIGH },
  { title: 'Критический', value: Priority.VERY_HIGH },
];

export const PAGINATION_RANGE_MAP: Record<
  TableTicketsTypes,
  number | undefined
> = {
  [TableTicketsTypes.FULL]: 1,
  [TableTicketsTypes.BY_CONTRACT_IDS]: 0,
  [TableTicketsTypes.FROM_DESKTOP]: 0,
  [TableTicketsTypes.FROM_DESKTOP_HISTORY]: 0,
};

export const ticketTabEntityMap = {
  MY_TICKETS: entityNames.MY_TICKET,
  ALL_TICKETS: entityNames.ALL_TICKET,
  MY_GROUPS_TICKETS: entityNames.GROUPS_TICKETS,
};

export const fetchTicketTypeSuccessMap = {
  update: fetchTicketTypesUpdateSuccess,
  join: fetchTicketTypesJoinSuccess,
};

export const fetchTicketStatusSuccessMap = {
  update: fetchTicketStatusUpdateSuccess,
  join: fetchTicketStatusJoinSuccess,
};
