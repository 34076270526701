import { RouterHref } from 'routes/routerHref';
import { checkPathnameWithTrailingSlash } from 'utils';

import { TicketTabType } from '../types';

export const getCurrentTableType = (pathname: string) => {
  if (checkPathnameWithTrailingSlash(pathname, RouterHref.Tickets)) {
    return TicketTabType.MY_TICKETS;
  }
  if (checkPathnameWithTrailingSlash(pathname, RouterHref.TicketsAll)) {
    return TicketTabType.ALL_TICKETS;
  }
  return TicketTabType.MY_GROUPS_TICKETS;
};
