import { ActionWithStatus } from 'core/types/action';
import { ActionFilter } from 'features/Actions/types';
import { ResponseWithMeta } from 'store/types';

export enum Actions {
  FETCH_ACTIONS_SUCCESS = 'ACTIONS/FETCH_ACTIONS_SUCCESS',
  FETCH_ACTIONS_REQUEST = 'ACTIONS/FETCH_ACTIONS_REQUEST',
  ACTIONS_LOADING_SHOW = 'ACTIONS/ACTIONS_LOADING_SHOW',
  ACTIONS_LOADING_HIDE = 'ACTIONS/ACTIONS_LOADING_HIDE',
  RESET_ACTIONS_STATE = 'ACTIONS/RESET_ACTIONS_STATE',
  FETCH_ACTIONS_CREATE_REQUEST = 'ACTIONS/FETCH_ACTIONS_CREATE_REQUEST',
  SET_ACTIONS_CREATE_STATE = 'ACTIONS/SET_ACTIONS_CREATE_STATE',
  FETCH_ACTION_BY_RESP_ID_REQUEST = 'ACTIONS/FETCH_ACTION_BY_RESP_ID_REQUEST',
  SET_ACTIONS_FOR_EDIT_RESPONSIBILITY = 'ACTIONS/SET_ACTIONS_FOR_EDIT_RESPONSIBILITY',
  SET_ACTIONS_FILTER = 'ACTIONS/SET_ACTIONS_FILTER',
  SET_CURRENT_ACTIONS_PAGE = 'ACTIONS/SET_CURRENT_ACTIONS_PAGE',
  SET_SORT_ACTIONS = 'ACTIONS/SET_SORT_ACTIONS',
  SET_SIZE_PAGE_ACTIONS = 'ACTIONS/SET_SIZE_PAGE_ACTIONS',
  RESET_ACTIONS_CREATE = 'ACTIONS/RESET_ACTIONS_CREATE',
}

export interface ShowActionsLoadingAction {
  type: Actions.ACTIONS_LOADING_SHOW;
}

export interface HideActionsLoadingAction {
  type: Actions.ACTIONS_LOADING_HIDE;
}

export interface FetchActionsRequestAction {
  type: Actions.FETCH_ACTIONS_REQUEST;
}

export interface FetchActionsSuccessAction {
  type: Actions.FETCH_ACTIONS_SUCCESS;
  payload: ResponseWithMeta<ActionWithStatus[]>;
}

export interface FetchActionsCreateRequestAction {
  type: Actions.FETCH_ACTIONS_CREATE_REQUEST;
  payload: string;
}

export interface SetActionsCreateAction {
  type: Actions.SET_ACTIONS_CREATE_STATE;
  payload: ActionWithStatus[];
}

export interface ResetActionsStateAction {
  type: Actions.RESET_ACTIONS_STATE;
}

export interface FetchActionsByRespIdAction {
  type: Actions.FETCH_ACTION_BY_RESP_ID_REQUEST;
}

export interface SetActionsForEditRespAction {
  type: Actions.SET_ACTIONS_FOR_EDIT_RESPONSIBILITY;
}

export interface SetActionsFilterAction {
  type: Actions.SET_ACTIONS_FILTER;
  payload: ActionFilter;
}

export interface SetCurrentActionsPageAction {
  type: Actions.SET_CURRENT_ACTIONS_PAGE;
  payload: number;
}

export interface SetSizePageAction {
  type: Actions.SET_SIZE_PAGE_ACTIONS;
  payload: number;
}

export interface SetSortActionsAction {
  type: Actions.SET_SORT_ACTIONS;
  payload: string;
}

export interface ResetActionsCreateAction {
  type: Actions.RESET_ACTIONS_CREATE;
}

export type ActionsTypes =
  | ShowActionsLoadingAction
  | HideActionsLoadingAction
  | FetchActionsRequestAction
  | FetchActionsSuccessAction
  | ResetActionsStateAction
  | FetchActionsByRespIdAction
  | SetActionsCreateAction
  | SetActionsForEditRespAction
  | SetActionsFilterAction
  | SetCurrentActionsPageAction
  | SetSizePageAction
  | SetSortActionsAction;
