import cn from 'clsx';
import { debounce } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  getValueFromValueType,
  RadioSlide,
  Select,
  ValueType,
} from 'components';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { createSelectValue } from 'core/utils';
import { useCreateArticleForm } from 'features/KnowledgeBase/views/KBCreateArticle/hooks';

import { TABS_ARTICLE_ACCESS } from '../../../../constants';
import { CategoryArticle } from '../../../../types';
import {
  fetchCategoriesRequestArticleCreate,
  fetchInitialOrganizationRequestArticleCreate,
  fetchOrganizationsRequestArticleCreate,
  fetchSubCategoriesRequestArticleCreate,
  setOrganizationsFilterArticleCreate,
} from '../../ducks/actions';
import {
  getArticleCategoriesSelectList,
  getArticleOrganizationsSelectList,
  getInitialOrganization,
  getSubCategoriesSelectList,
} from '../../ducks/selectors';

import styles from './KBCreateArticleHeaderForm.module.scss';

interface KBCreateArticleHeaderFormProps {
  className?: string;
  form: ReturnType<typeof useCreateArticleForm>;
}

export const KBCreateArticleHeaderForm: FC<KBCreateArticleHeaderFormProps> = ({
  className,
  form,
}) => {
  const dispatch = useDispatch();

  const { state, methods } = form;
  const { control, isEditMode } = state;
  const { setValue } = methods;

  const organizationsOptions = useSelector(getArticleOrganizationsSelectList);
  const categoriesOptions = useSelector(getArticleCategoriesSelectList);
  const subCategories = useSelector(getSubCategoriesSelectList);
  const initialOrganization = useSelector(getInitialOrganization);

  const [orgInput, setOrgInput] = useState<string | undefined>();

  const { state: locationState } = useLocation<{
    category: CategoryArticle;
  }>();
  const categoryLocation = locationState?.category;

  const fetchCategoriesByOrgId = (value: ValueType<string>) => {
    const orgId = getValueFromValueType(value);
    if (orgId) {
      dispatch(fetchCategoriesRequestArticleCreate(orgId));
    }
  };

  const fetchSubCategories = (value: ValueType<string>) => {
    const categoryId = getValueFromValueType(value);
    if (categoryId) {
      dispatch(fetchSubCategoriesRequestArticleCreate(categoryId));
    }
  };

  const onFilterOrganization = debounce((valueFilter: string) => {
    setOrgInput(valueFilter);
    dispatch(setOrganizationsFilterArticleCreate(valueFilter));
    dispatch(fetchOrganizationsRequestArticleCreate());
  }, DEFAULT_DEBOUNCE_DELAY);

  useEffect(() => {
    if (!isEditMode) {
      dispatch(fetchOrganizationsRequestArticleCreate());
    }
  }, []);

  useEffect(() => {
    if (categoryLocation) {
      dispatch(
        fetchInitialOrganizationRequestArticleCreate(
          categoryLocation?.organizationId || ''
        )
      );
    }
  }, []);

  useEffect(() => {
    if (categoryLocation && initialOrganization && initialOrganization?.id) {
      const parentCategory = createSelectValue(
        categoryLocation.parentTitle,
        categoryLocation.parentId
      );
      setValue(
        parentCategory ? 'category' : 'parentCategory',
        createSelectValue(categoryLocation.title, categoryLocation.id)
      );
      if (parentCategory) {
        fetchSubCategories(parentCategory);
        setValue('parentCategory', parentCategory);
      }
      setValue(
        'organization',
        createSelectValue(initialOrganization?.title, initialOrganization.id)
      );
      dispatch(fetchCategoriesRequestArticleCreate(initialOrganization.id));
    }
  }, [categoryLocation, initialOrganization]);

  return (
    <form className={cn(styles.KBCreateArticleHeaderForm, className)}>
      <div className={styles.KBCreateArticleHeaderForm__selects}>
        <Controller
          control={control}
          name="organization"
          key="organization"
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <Select<string>
                className={styles.KBCreateArticleHeaderForm__select}
                onChange={(value) => {
                  field.onChange(value);
                  setValue('category', null);
                  setValue('parentCategory', null);
                  fetchCategoriesByOrgId(value);
                }}
                value={field.value}
                options={organizationsOptions}
                isSearchable
                inputValue={orgInput}
                onChangeInput={onFilterOrganization}
                disabled={isEditMode}
                placeholder="Организация"
                mobileModalTitle="организацию"
              />
            );
          }}
        />
        <Controller
          control={control}
          name="parentCategory"
          key="parentCategory"
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <Select<string>
                className={styles.KBCreateArticleHeaderForm__select}
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  setValue('category', null);
                  fetchSubCategories(value);
                }}
                options={categoriesOptions}
                disabled={isEditMode}
                label="Категория"
                mobileModalTitle="категорию"
              />
            );
          }}
        />
        <Controller
          control={control}
          name="category"
          key="category"
          render={({ field }) => {
            return (
              <Select<string>
                className={styles.KBCreateArticleHeaderForm__select}
                onChange={field.onChange}
                value={field.value}
                options={subCategories}
                label="Подкатегория"
                disabled={isEditMode}
                mobileModalTitle="подкатегорию"
              />
            );
          }}
        />
      </div>
      <Controller
        control={control}
        name="accessType"
        rules={{
          required: true,
        }}
        render={({ field }) => {
          return (
            <RadioSlide
              items={TABS_ARTICLE_ACCESS}
              value={field.value}
              onChange={field.onChange}
              className={styles.KBCreateArticleHeaderForm__selectSlide}
              classNameTitleLabel={
                styles.KBCreateArticleHeaderForm__selectTitle
              }
            />
          );
        }}
      />
    </form>
  );
};
