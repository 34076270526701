import { TicketsFilterForRender } from 'core/types/tickets';

import { TicketsFilterForRequest } from '../types';

interface Args {
  filter?: TicketsFilterForRender;
}

export const getStatusTicketFilterRequest = ({
  filter = {},
}: Args): TicketsFilterForRequest => {
  const { specialistIds, clientIds, workGroupIds } = filter;

  return {
    clientIds,
    specialistIds,
    workGroupIds: workGroupIds || [],
  } as TicketsFilterForRequest;
};
