import { Organization } from 'core/types/organization';
import { CategoryArticle } from 'features/KnowledgeBase/types';
import { ApiClient, getEnv } from 'utils';

import { endpoints } from './endpoints';

export const request = {
  organizationsArticleRequest: (filter?: { organizationTitle: string }) =>
    ApiClient.post<Organization[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getOrganizationsForArticleCreate(),
      data: filter,
    }),
  categoriesArticleRequest: (orgId: string) =>
    ApiClient.get<CategoryArticle[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getCategoriesByOrganizationId(orgId),
    }),
  fetchOrganizationRequest: (id: string) =>
    ApiClient.get<Organization>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getInitialOrganization(id),
    }),
};
