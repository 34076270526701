import { PAGE_SIZE_SEARCH_SELECT } from 'constants/meta';
import { User, UsersFilterToRequest } from 'core/types/user';
import { CreateUserDataToRequest } from 'features/User/types';
import { ResponseWithMeta } from 'store/types';
import { ApiClient, getEnv } from 'utils';

import { endpoints } from './endpoints';

export const request = {
  fetchUsers: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: UsersFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<User[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUsers(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: filter,
    }),
  fetchUser: (id: string) =>
    ApiClient.get<User>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUser(id),
      data: {},
    }),
  createUser: (body: CreateUserDataToRequest) =>
    ApiClient.post<User>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createUser(),
      data: body,
    }),
  editUser: (body: CreateUserDataToRequest) =>
    ApiClient.put<User>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.editUser(),
      data: body,
    }),
  fetchUsersByGroupId: (
    id: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: UsersFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<User[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUsersByGroupId(id),
      params: { pageNum, pageSize, sort },
      data: filter,
    }),
  updateUser: (body: User) =>
    ApiClient.put<User>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createUser(),
      data: body,
    }),
  fetchSubordinates: (
    contractId: string,
    systemId: string,
    pageNum = 0,
    pageSize = PAGE_SIZE_SEARCH_SELECT,
    sort?: string,
    filter?: UsersFilterToRequest
  ) =>
    ApiClient.post<User[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSubordinates(contractId, systemId),
      params: { pageNum, pageSize, sort },
      data: filter,
    }),
  updateUserRolesInGroup: (id: string, groupId: string, body: string[]) =>
    ApiClient.put<User>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUpdateUserRolesInGroup(id, groupId),
      data: body,
    }),
  updateUserRolesInSystem: (id: string, body: string[]) =>
    ApiClient.put<User>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUpdateUserRolesInSystem(id),
      data: body,
    }),
  fetchUsersTicket: (
    pageNum = 0,
    pageSize = PAGE_SIZE_SEARCH_SELECT,
    sort?: string,
    filter?: UsersFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<User[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUsersTickets(),
      params: { pageNum, pageSize, sort },
      data: filter,
    }),
  checkIsExistLogin: (login: string) =>
    ApiClient.get<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCheckIsExistLogin(login),
    }),
  checkIsExistEmail: (email: string) =>
    ApiClient.get<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCheckIsExistEmail(email),
    }),
  checkIsExistPhone: (phone: string) =>
    ApiClient.get<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCheckIsExistPhone(phone),
    }),
  fetchUserByContractId: (
    id: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: UsersFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<User[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUsersByContractId(id),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: filter,
    }),
  deleteUser: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.deleteUser(id),
    }),
};
