import { RenderTypes, TableColumns } from 'components';
import { OrganizationType } from 'core/types';
import { OrganizationsFilter as OrganizationsFilterData } from 'core/types/organization';

export const COLUMNS_ORGANIZATIONS_FULL: TableColumns = [
  {
    title: 'Название',
    sortField: 'title',
    fieldName: 'title',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  {
    title: 'Тип',
    sortField: 'type',
    fieldName: 'type',
    renderType: RenderTypes.CONDITION,
  },
  {
    title: 'Домен',
    fieldName: 'domain',
    sortField: 'domain',
  },
  {
    title: 'Описание',
    fieldName: 'description',
    sortField: 'description',
  },
  {
    title: 'Адрес',
    fieldName: 'address',
    sortField: 'address',
  },
];

export const TITLE_TYPE_ORGANIZATION = {
  [OrganizationType.CUSTOMER]: 'Клиентская',
  [OrganizationType.SERVICE]: 'Обслуживающая',
  [OrganizationType.PARTNER]: 'Партнерская',
};

export const INITIAL_FILTER_VALUES: OrganizationsFilterData = {
  title: '',
  domain: '',
  description: '',
  address: '',
  organizationTypes: null,
};
