import { SettingField } from '../../SettingFields/types';
import { CustomFieldTypeForFilter } from '../types';

export const getCustomFieldType = (customColumns?: SettingField[]) => {
  const result: Record<string, CustomFieldTypeForFilter> = {};

  if (!customColumns) {
    return {};
  }

  customColumns?.forEach((customColumn) => {
    if (customColumn?.customFieldType) {
      result[customColumn.naturalKey] = {
        fieldId: customColumn.naturalKey,
        type: customColumn?.customFieldType,
      };
    }
  });

  return result;
};
