import { OrganizationType } from 'core/types';

export enum AccessArticleType {
  COMMON = 'COMMON',
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
}

export type Article = {
  id?: string;
  title: string;
  text: string;
  organizationId: string;
  organizationTitle: string;
  organizationType?: OrganizationType;
  userId: string;
  userFullName: string;
  categoryId: string;
  categoryTitle: string;
  parentCategoryTitle?: string;
  parentCategoryId?: string;
  accessType: AccessArticleType;
  systemId?: string;
  systemVersion?: string;
  systemTitle?: string;
  created: Date;
  updated: Date;
  isSolution?: boolean;
};
