import { CalendarIcon, CheckCircleIcon, TextSizeIcon } from 'assets/icons';
import { CustomFieldType } from 'components';

import styles from './utils.module.scss';

export const getPropsCustomFieldType = (type: CustomFieldType) => {
  const components = {
    [CustomFieldType.FIELD_DATE]: () => ({
      text: 'DatePicker',
      icon: <CalendarIcon className={styles.utils__icon} />,
    }),
    [CustomFieldType.FIELD_FLAG]: () => ({
      text: 'Flag',
      icon: <CheckCircleIcon className={styles.utils__icon} />,
    }),
    [CustomFieldType.FIELD_TEXT]: () => ({
      text: 'Text',
      icon: <TextSizeIcon className={styles.utils__icon} />,
    }),
  };
  return components[type]();
};
