import { OrganizationType } from 'core/types';

export interface DataForAccessEdit {
  myOrganizationId?: string;
  currentOrganizationId?: string;
  currentOrganizationType?: string;
  isAccessCreateArticleInClientOrganization: boolean;
  isAccessCreateArticleInMyOrganization: boolean;
}
export const getIsAccessEditCategoryOrArticle = ({
  myOrganizationId,
  currentOrganizationId,
  currentOrganizationType,
  isAccessCreateArticleInClientOrganization,
  isAccessCreateArticleInMyOrganization,
}: DataForAccessEdit) => {
  const isCategoryFromMyOrganization =
    currentOrganizationId && currentOrganizationId === myOrganizationId;
  const isCategoryFromClientOrganization =
    currentOrganizationType &&
    currentOrganizationType === OrganizationType.CUSTOMER;

  return (
    (isAccessCreateArticleInMyOrganization && isCategoryFromMyOrganization) ||
    (isAccessCreateArticleInClientOrganization &&
      isCategoryFromClientOrganization)
  );
};
