import { useMemo } from 'react';

import { SelectProps, ValueType } from '../types';

type UseBooleanValuesSelectProps<T> = Pick<
  SelectProps<T>,
  | 'isSearchable'
  | 'disabled'
  | 'showAddComponent'
  | 'options'
  | 'maxSelectedOptions'
  | 'isClearable'
  | 'isMulti'
  | 'isChip'
  | 'isMultiSearch'
> & {
  inputValue?: string;
  optionSelected: ValueType<T>;
  openDropdown?: boolean;
};

export const useBooleanValuesSelect = <T>({
  isSearchable,
  disabled,
  showAddComponent,
  options,
  inputValue,
  optionSelected,
  maxSelectedOptions,
  isClearable,
  openDropdown,
  isMulti,
  isChip,
  isMultiSearch,
}: UseBooleanValuesSelectProps<T>) => { //eslint-disable-line
  const selectDisabled = useMemo(() => {
    if (showAddComponent) {
      return false;
    }
    if (isSearchable) {
      return disabled || (!options?.length && !inputValue?.length);
    }
    return disabled || !options?.length;
  }, [isSearchable, disabled, options, inputValue]);

  const inputDisabled = useMemo(() => {
    if (optionSelected instanceof Array) {
      return !!(
        maxSelectedOptions && optionSelected.length >= maxSelectedOptions
      );
    }
    if (disabled) return true;
    return undefined;
  }, [maxSelectedOptions, optionSelected]);

  const isVisiblePlaceholder = useMemo(
    (): boolean =>
      !optionSelected ||
      (optionSelected instanceof Array && !optionSelected.length),
    [optionSelected]
  );

  const hasSelectedOption: boolean = useMemo((): boolean => {
    if (optionSelected instanceof Array) {
      return Boolean(optionSelected.length);
    }

    return Boolean(optionSelected);
  }, [optionSelected]);

  const conditions = [
    !disabled,
    hasSelectedOption,
    isClearable,
    !inputValue?.length,
    !openDropdown,
  ];
  const isArraySelected =
    Array.isArray(optionSelected) && !!optionSelected?.length;

  const showClearButton =
    conditions.every(Boolean) || (isArraySelected && isSearchable && isMulti);

  const showValueInsideInput = isSearchable
    ? !inputValue?.length && (!openDropdown || isMulti)
    : true;

  const showInputComponent = isSearchable && (openDropdown || isMulti);
  const showMultiValueInsideInput = isMulti && optionSelected;

  const isNoOption =
    optionSelected instanceof Array ? !optionSelected.length : !optionSelected;

  const isArray = Array.isArray(optionSelected);
  const hasLength = isArray ? !!optionSelected?.length : false;

  const isLabelHidden =
    (isMulti && isChip && (hasLength || inputValue)) ||
    (isMultiSearch && hasLength);

  return {
    selectDisabled,
    inputDisabled,
    isVisiblePlaceholder,
    showClearButton,
    showValueInsideInput,
    showInputComponent,
    showMultiValueInsideInput,
    isNoOption,
    isLabelHidden,
  };
};
