import {
  ChangeEvent,
  FC,
  FunctionComponent,
  SVGProps,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DesktopIcon, MailIcon } from 'assets/icons';
import {
  Accordion,
  ToggleSwitch,
  Typography,
  TypographyVariants,
} from 'components';
import { getIsGroupSpecialist } from 'features/Auth';
import { splitProfileTabsByGroup } from 'features/Profile/utils/splitProfileTabsByGroup';

import {
  fetchNotificationSettingsRequest,
  updateNotificationSettingsRequest,
} from '../../ducks/actions';
import { getNotificationSettingsLocale } from '../../ducks/selectors';
import { ClickElem, ProfileTab, SwitchSection } from '../../types';
import { GroupSpecialistForm } from '../GroupSpecialistForm';

import styles from './NotificationsSettings.module.scss';

interface TypeNotificationsComponentProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  title?: string;
}

const TypeNotificationsComponent: FC<TypeNotificationsComponentProps> = ({
  icon: Icon,
  title,
}) => {
  return (
    <Typography
      variant={TypographyVariants.o}
      component="div"
      className={styles.notificationsSettings__type}
    >
      <div className={styles.notificationsSettings__typeIconWrapper}>
        <Icon className={styles.notificationsSettings__typeIcon} />
      </div>
      {title}
    </Typography>
  );
};

export const NotificationsSettings = () => {
  const dispatch = useDispatch();

  const notificationsSettingsLocale = useSelector(
    getNotificationSettingsLocale
  );
  const isGroupSpecialist = useSelector(getIsGroupSpecialist);

  const convertedData = splitProfileTabsByGroup(
    notificationsSettingsLocale?.mainNotificationConfigs || []
  );

  const handleSwitchSection = useCallback(
    ({ tabGroup, checked, notifyType }: SwitchSection) => {
      const result = [
        ...(notificationsSettingsLocale?.mainNotificationConfigs || []),
      ].map((item) => {
        if (item.tabGroup === tabGroup) {
          if (notifyType === 'push') {
            return {
              ...item,
              notifyByPush: checked,
            };
          }
          return {
            ...item,
            notifyByEmail: checked,
          };
        }
        return item;
      });
      dispatch(
        updateNotificationSettingsRequest({
          ...notificationsSettingsLocale,
          mainNotificationConfigs: result,
        })
      );
    },
    [notificationsSettingsLocale, updateNotificationSettingsRequest]
  );

  const handleClickElem = useCallback(
    (elem: ProfileTab) =>
      ({ titleNotification, checked, notifyType }: ClickElem) => {
        const result = [
          ...(notificationsSettingsLocale?.mainNotificationConfigs || []),
        ];

        const index = result.findIndex(
          (setting) => setting.titleNotification === titleNotification
        );

        if (notifyType === 'push') {
          result.splice(index, 1, {
            ...elem,
            notifyByPush: checked,
          });
        } else {
          result.splice(index, 1, {
            ...elem,
            notifyByEmail: checked,
          });
        }
        dispatch(
          updateNotificationSettingsRequest({
            ...notificationsSettingsLocale,
            mainNotificationConfigs: result,
          })
        );
      },
    [notificationsSettingsLocale, updateNotificationSettingsRequest]
  );

  useEffect(() => {
    dispatch(fetchNotificationSettingsRequest());
  }, []);

  const groupSpecialistForm = isGroupSpecialist && (
    <GroupSpecialistForm className={styles.notificationsSettings__groupsForm} />
  );

  const content = Object.keys(convertedData).map((item) => {
    const pushChecked = convertedData[item].reduce(
      (result, currentElem) => !!currentElem?.notifyByPush || result,
      false
    );
    const emailChecked = convertedData[item].reduce(
      (result, currentElem) => !!currentElem?.notifyByEmail || result,
      false
    );

    const onChangePushSection = (event: ChangeEvent<HTMLInputElement>) => {
      handleSwitchSection({
        tabGroup: item,
        checked: event.target.checked,
        notifyType: 'push',
      });
    };

    const onChangeEmailSection = (event: ChangeEvent<HTMLInputElement>) => {
      handleSwitchSection({
        tabGroup: item,
        checked: event.target.checked,
        notifyType: 'email',
      });
    };

    return (
      <Accordion
        title={item}
        contentClassName={styles.notificationsSettings__accordionContent}
        headerContent={
          <div className={styles.notificationsSettings__doubleSwitch}>
            <ToggleSwitch
              checked={pushChecked}
              onChange={onChangePushSection}
              id={item}
            />
            <ToggleSwitch
              checked={emailChecked}
              onChange={onChangeEmailSection}
              id={item}
            />
          </div>
        }
        key={item}
      >
        {convertedData[item]?.map((elem) => {
          const onChangePushElem = (event: ChangeEvent<HTMLInputElement>) => {
            handleClickElem(elem)({
              titleNotification: elem.titleNotification || '',
              checked: event.target.checked,
              notifyType: 'push',
            });
          };

          const onChangeEmailElem = (event: ChangeEvent<HTMLInputElement>) => {
            handleClickElem(elem)({
              titleNotification: elem.titleNotification || '',
              checked: event.target.checked,
              notifyType: 'email',
            });
          };

          return (
            <div
              className={styles.notificationsSettings__accordionItem}
              key={elem?.titleNotification}
            >
              {elem.description}
              <div className={styles.notificationsSettings__doubleSwitch}>
                <ToggleSwitch
                  checked={!!elem.notifyByPush}
                  onChange={onChangePushElem}
                  id={elem?.titleNotification || ''}
                />
                <ToggleSwitch
                  checked={!!elem.notifyByEmail}
                  onChange={onChangeEmailElem}
                  id={elem?.titleNotification || ''}
                />
              </div>
            </div>
          );
        })}
      </Accordion>
    );
  });

  return (
    <div className={styles.notificationsSettings}>
      <Typography
        variant={TypographyVariants.h4}
        component="div"
        className={styles.notificationsSettings__title}
      >
        Уведомления
        <div className={styles.notificationsSettings__typeBlock}>
          <TypeNotificationsComponent icon={DesktopIcon} title="PUSH" />
          <TypeNotificationsComponent icon={MailIcon} title="E-MAIL" />
        </div>
      </Typography>
      {content}
      {groupSpecialistForm}
    </div>
  );
};
