import { ChangeEvent, FC, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MoreTag, Table, ToggleSwitch } from 'components';
import { updateTrigger } from 'features/Triggers/ducks';
import { getPreparedDataForUpdate } from 'features/Triggers/utils';
import { KeyValueOption } from 'types/models/meta';

import { TABLE_HEADER_TRIGGERS } from '../../constants';
import { getLoading } from '../../ducks/selectors';
import { CreateTrigger, Trigger } from '../../types';
import { TriggersFilter } from '../TriggersFilter';

import styles from './TriggersFullTable.module.scss';

const getMoreTag = (data?: KeyValueOption[]) => {
  return (!!data?.length && <MoreTag data={data} />) || '-';
};

const getDataTable = (
  triggers?: Trigger[],
  triggerClick?: (event: MouseEvent<HTMLButtonElement>) => void,
  updateHandler?: (data: CreateTrigger) => void
) =>
  triggers?.map((trigger) => {
    const {
      title,
      id,
      organization,
      system,
      ticketTypes,
      ticketPriorities,
      environments,
      clients,
      status,
      customConditions,
      defaultConditions,
      isEnable,
      targetStatus,
    } = trigger;

    const customField = customConditions.map(
      (conditions) => conditions.customField
    );

    const onChangeAction = (event: ChangeEvent<HTMLInputElement>) => {
      if (updateHandler) {
        const selectedTrigger = triggers.find((item) => item.id === trigger.id);

        if (selectedTrigger) {
          const preparedData = getPreparedDataForUpdate({
            isEnable: event.target.checked,
            selectedTrigger,
            customConditions,
            defaultConditions,
            targetStatus,
          });

          updateHandler(preparedData);
        }
      }
    };

    return {
      id,
      title: (
        <button
          onClick={triggerClick}
          className={styles.triggersFullTable__title}
        >
          {title}
        </button>
      ),
      isActive: <ToggleSwitch checked={isEnable} onChange={onChangeAction} />,
      organization: <>{organization?.value || '-'}</>,
      system: <>{system?.value || '-'}</>,
      status: <>{status?.value || '-'}</>,
      ticketTypes: getMoreTag(ticketTypes),
      ticketPriorities: getMoreTag(ticketPriorities),
      environments: getMoreTag(environments),
      clients: getMoreTag(clients),
      customFields: getMoreTag(customField),
    };
  }) || [];

interface TriggersFullTableProps {
  triggers?: Trigger[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleClickTrigger?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
  handleClickTriggerEdit?: () => void;
}

export const TriggersFullTable: FC<TriggersFullTableProps> = ({
  triggers,
  sort,
  handleSort,
  handleClickTrigger,
  activeRowId,
  showFilterRow,
  isFilterActive,
  handleClickTriggerEdit,
}) => {
  const dispatch = useDispatch();

  const updateHandler = (data: CreateTrigger) => {
    dispatch(updateTrigger(data));
  };

  const onTriggerClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (handleClickTriggerEdit) {
      handleClickTriggerEdit();
    }
  };

  const data = getDataTable(triggers, onTriggerClick, updateHandler);

  const loading = useSelector(getLoading);

  return (
    <Table
      data={data}
      columns={TABLE_HEADER_TRIGGERS}
      sort={sort}
      onRowClick={handleClickTrigger}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={showFilterRow && <TriggersFilter />}
      disabledSort={loading}
    />
  );
};
