import { getUrl } from 'utils';

export const endpoints = {
  fetchArticlesMain: () => getUrl(`v1/article/filter`),
  fetchOrganizationsArticlesMain: () => getUrl(`v1/user-access/organizations`),
  fetchCategories: (organizationId?: string) => {
    const endOfUrl = organizationId ? `/${organizationId}` : '';
    return getUrl(`v1/category/parent${endOfUrl}`);
  },
};
