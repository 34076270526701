import { CustomFieldType, TabItem } from 'components';
import { PAGE_SIZE_SEARCH_SELECT } from 'constants/meta';
import { StorageCustomField } from 'core/types/storage';

export const DEFAULT_CREATE_STORAGE_FORM_VALUES = {
  title: '',
  description: '',
  organizations: [],
  editorsGroups: [],
  viewersGroups: [],
  size: 1,
  customField: {
    title: '',
    description: '',
    type: CustomFieldType.FIELD_TEXT,
  },
};

export const DEFAULT_FOR_CREATE_STORAGE_VALUES = {
  title: '',
  pageSize: PAGE_SIZE_SEARCH_SELECT,
  sort: 'TITLE_ASC',
  filter: {},
};

export const SLIDE_RADIO_TABS: TabItem<CustomFieldType>[] = [
  {
    title: 'Текстовый',
    value: CustomFieldType.FIELD_TEXT,
  },
  {
    title: 'Дата',
    value: CustomFieldType.FIELD_DATE,
  },
  {
    title: 'Бинарный',
    value: CustomFieldType.FIELD_FLAG,
  },
];

export const DEFAULT_ATTRIBUTES: StorageCustomField[] = [
  {
    title: 'Название',
    description: '',
    type: CustomFieldType.FIELD_TEXT,
    required: true,
  },
  {
    title: 'Описание',
    description: '',
    type: CustomFieldType.FIELD_TEXT,
    required: true,
  },
];
