import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SelectOption } from 'components';
import { getScreenWidth } from 'core/ducks/selectors';
import { ScreenWidth } from 'core/types';
import {
  KBArticleDropProps,
  KBCategoryDropProps,
} from 'features/KnowledgeBase/components';
import { ActionsProps } from 'features/KnowledgeBase/components/KBArticlesSearch';
import { ValuesProps } from 'features/KnowledgeBase/components/KBOrganizationSelect/KBOrganizationSelect';
import {
  CategoryArticle,
  KBArticleTableFilterToRequest,
} from 'features/KnowledgeBase/types';
import { FetchArticlesParams } from 'features/KnowledgeBase/views/KBMain/ducks/types';
import { useInfiniteScroll } from 'hooks';

import {
  createCategoryRequestRightArticlesTable,
  deleteArticleRequestRightArticlesTable,
  deleteCategoryRequestRightArticlesTable,
  editArticleRequestRightArticlesTable,
  editCategoryRequestRightArticlesTable,
  fetchArticlesRightArticlesTableRequest,
  fetchCategoriesRequestRightArticlesTable,
  fetchOrganizationsRequestRightArticlesTable,
  resetArticlesStateRightArticlesTable,
  resetCategoriesStateRightArticlesTable,
  setArticlesFilterRightArticlesTable,
  setSearchValueRightArticlesTable,
  setSelectedOrganizationRightArticlesTable,
} from '../ducks/actions';
import {
  getArticlesRightTable,
  getCategoriesArticlesRightTable,
  getLoadingArticlesRightTable,
  getLoadingCategoriesArticlesRightTable,
  getOrganizationsArticlesRightTableSelectList,
  getPaginationArticlesRightTable,
  getSearchValueArticlesRightTable,
  getSelectedOrganizationArticlesRightTable,
} from '../ducks/selectors';

export const useKBTableArticlesSearch = () => {
  const dispatch = useDispatch();
  const organization = useSelector(getSelectedOrganizationArticlesRightTable);
  const searchValue = useSelector(getSearchValueArticlesRightTable);
  const articles = useSelector(getArticlesRightTable);
  const categories = useSelector(getCategoriesArticlesRightTable);
  const loadingCategories = useSelector(getLoadingCategoriesArticlesRightTable);
  const screenWidth = useSelector(getScreenWidth);
  const widthDesktop =
    screenWidth === ScreenWidth.DESKTOP ||
    screenWidth === ScreenWidth.DESKTOP_SMALL;
  const loadingArticles = useSelector(getLoadingArticlesRightTable);
  const {
    totalElements,
    pageNum = 0,
    pageSize,
  } = useSelector(getPaginationArticlesRightTable);

  const { totalPage, hasMore } = useInfiniteScroll({
    pageNum,
    pageSize,
    totalElements,
    loading: loadingArticles,
  });

  const onChangeTitle = (id: string, newTitle = '') => {
    dispatch(editCategoryRequestRightArticlesTable({ id, title: newTitle }));
  };

  const onDeleteCategory = (id?: string) => {
    if (id) {
      dispatch(deleteCategoryRequestRightArticlesTable(id));
    }
  };

  const onSubCategoryCreate = (data: Partial<CategoryArticle>) => {
    dispatch(createCategoryRequestRightArticlesTable(data));
  };

  const onChangeTitleArticle = (id: string, newTitle = '') => {
    dispatch(editArticleRequestRightArticlesTable({ id, title: newTitle }));
  };

  const onArticleDelete = (id: string) => {
    dispatch(deleteArticleRequestRightArticlesTable(id));
  };

  const setNextPageSystem = () => {
    if (!loadingArticles && totalPage > pageNum) {
      dispatch(
        fetchArticlesRightArticlesTableRequest({
          updateType: 'join',
          page: pageNum + 1,
        })
      );
    }
  };

  const onFilterOrganization = (valueFilter: string) => {
    dispatch(
      fetchOrganizationsRequestRightArticlesTable({
        organizationTitle: valueFilter,
      })
    );
  };

  const fetchCategories = () => {
    dispatch(fetchCategoriesRequestRightArticlesTable());
  };

  const fetchOrganizations = () => {
    dispatch(fetchOrganizationsRequestRightArticlesTable({}));
  };

  const resetCategories = () => {
    dispatch(resetCategoriesStateRightArticlesTable());
  };

  const onChangeOrganization = (value: SelectOption) => {
    dispatch(setSelectedOrganizationRightArticlesTable(value));
    dispatch(fetchCategoriesRequestRightArticlesTable(value?.value));
  };

  const setSearchValueToState = (value?: string) => {
    dispatch(setSearchValueRightArticlesTable(value || ''));
  };
  const resetState = () => {
    dispatch(resetArticlesStateRightArticlesTable());
  };

  const fetch = (params: FetchArticlesParams) => {
    dispatch(fetchArticlesRightArticlesTableRequest(params));
  };

  const setFilter = (filter: KBArticleTableFilterToRequest) => {
    dispatch(setArticlesFilterRightArticlesTable(filter));
  };

  const onCreateCategory = useCallback(
    (data?: Partial<CategoryArticle>) => {
      if (data) {
        dispatch(createCategoryRequestRightArticlesTable(data));
      }
    },
    [dispatch]
  );

  const selectOrganizationsProps = {
    getSelectList: getOrganizationsArticlesRightTableSelectList,
  };

  const valuesProps: ValuesProps = {
    searchValue,
    organizationSelected: organization,
  };

  const categoryDropProps: KBCategoryDropProps = {
    onChangeTitle,
    onDeleteCategory,
    onSubCategoryCreate,
    withArticleCreate: true,
  };

  const actionsOrganizationsProps = {
    onInputFilter: onFilterOrganization,
    fetchCategories,
    fetchOrganizations,
    resetCategories,
    onChangeOrganization,
    setSearchValueToState,
  };

  const articleDropProps: KBArticleDropProps = {
    onChangeTitle: onChangeTitleArticle,
    onDelete: onArticleDelete,
    withChangeArticleTitle: true,
    withDeleteArticle: true,
  };

  const selectorsSearchProps = {
    getList: getArticlesRightTable,
    getLoading: getLoadingArticlesRightTable,
    getOrganizationsSelected: getSelectedOrganizationArticlesRightTable,
    getPaginationArticles: getPaginationArticlesRightTable,
  };

  const actionsSearchProps: ActionsProps = {
    resetState,
    fetch,
    setFilter,
    setSearchValueToState,
    fetchCategories,
  };

  return {
    state: {
      actionsSearchProps,
      selectorsSearchProps,
      actionsOrganizationsProps,
      selectOrganizationsProps,
      categoryDropProps,
      valuesProps,
      articleDropProps,
      widthDesktop,
      articles,
      categories,
      loadingCategories,
      hasMore,
      organization,
      searchValue,
      loadingArticles,
    },
    methods: {
      setNextPageSystem,
      onCreateCategory,
    },
  };
};
