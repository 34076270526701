import { RoleType } from 'core/types';

import { Organization } from './organization';
import { Responsibility } from './responsibility';

export type Role = {
  id: string;
  name: string;
  description: string;
  type: RoleType;
  manager?: boolean;
  responsibilityList?: Responsibility[];
  organization?: Omit<Organization, 'workGroupList' | 'contractList'>;
};

export enum TableRolesTypes {
  FULL = 'FULL',
  FROM_USERS = 'FROM_USERS',
  ADD_MODAL = 'ADD_MODAL',
  FROM_GROUPS = 'FROM_GROUPS',
}
