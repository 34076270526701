import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { getValueFromValueType, SelectOption } from 'components';
import { Article } from 'core/types/knowledgeBase';
import { Organization } from 'core/types/organization';
import {
  createCategory,
  deleteArticle,
  deleteCategory,
  editArticle,
  editCategory,
  fetchCategory,
} from 'features/KnowledgeBase/ducks/saga';
import {
  CategoryArticle,
  KBArticleTableFilter,
  KBArticleTableFilterToRequest,
} from 'features/KnowledgeBase/types';
import { ResponseWithMeta } from 'store/types';
import { createError } from 'utils';

import {
  categoriesLoadingHideRightArticlesTable,
  categoriesLoadingShowRightArticlesTable,
  createCategoryRequestRightArticlesTable,
  deleteArticleRequestRightArticlesTable,
  deleteCategoryRequestRightArticlesTable,
  editArticleRequestRightArticlesTable,
  editCategoryRequestRightArticlesTable,
  fetchArticlesJoinSuccessRightArticlesTable,
  fetchArticlesRightArticlesTableRequest,
  fetchArticlesSuccessRightArticlesTable,
  fetchArticlesTableCategorySuccess,
  fetchArticlesTableSuccess,
  fetchCategoriesRequestRightArticlesTable,
  fetchCategoriesSuccessRightArticlesTable,
  fetchOrganizationsSuccessArticleTable,
  fetchOrganizationsSuccessRightArticlesTable,
  hideArticlesTableLoading,
  loadingHideRightArticlesTable,
  loadingShowRightArticlesTable,
  organizationsLoadingHideRightArticlesTable,
  organizationsLoadingShowRightArticlesTable,
  resetOnlyTableArticlesTableState,
  showArticlesTableLoading,
} from './actions';
import { request } from './api/requests';
import {
  getArticlesTableCategory,
  getArticlesTableFilter,
  getFilterArticlesRightTable,
  getPaginationArticlesRightTable,
  getPropsArticlesTable,
  getSelectedOrganizationArticlesRightTable,
} from './selectors';
import {
  ArticlesTable,
  DeleteArticlesTableRequestAction,
  FetchArticlesTableCategoryRequestAction,
  FetchArticlesTableRequestAction,
  FetchOrganizationsRequestArticleTableAction,
  FetchOrganizationsRequestRightArticlesTableAction,
} from './types';

export const getFilterArticlesTableToRequest = (
  filter: KBArticleTableFilter,
  categoryId: string
): KBArticleTableFilterToRequest => {
  const { organization, category, ...other } = filter;
  const organizationId = getValueFromValueType(organization);
  const categoryIdFromFilter = getValueFromValueType(category);
  return {
    organizationIds: organizationId ? [organizationId] : undefined,
    parentCategoryId: categoryIdFromFilter ? undefined : categoryId,
    categoryId: categoryIdFromFilter || categoryId,
    ...other,
  };
};

function* articlesTableFetch({ payload }: FetchArticlesTableRequestAction) {
  try {
    const {
      pageNum,
      pageSize,
      sortArticlesTable,
    }: ReturnType<typeof getPropsArticlesTable> = yield select(
      getPropsArticlesTable
    );
    const filter: ReturnType<typeof getArticlesTableFilter> = yield select(
      getArticlesTableFilter
    );

    yield put(showArticlesTableLoading());
    const articles: ResponseWithMeta<Article[]> = yield call(
      request.fetchArticles,
      pageNum,
      pageSize,
      sortArticlesTable,
      getFilterArticlesTableToRequest(filter, payload)
    );
    yield put(fetchArticlesTableSuccess(articles));
    yield put(hideArticlesTableLoading());
  } catch (e) {
    createError(e);
    yield put(hideArticlesTableLoading());
  }
}

function* deleteArticleTable({ payload }: DeleteArticlesTableRequestAction) {
  try {
    const currentCategory: ReturnType<typeof getArticlesTableCategory> =
      yield select(getArticlesTableCategory);
    yield call(deleteArticle, payload);
    yield put(resetOnlyTableArticlesTableState());
    if (currentCategory?.id) {
      yield call(articlesTableFetch, {
        type: ArticlesTable.FETCH_ARTICLES_REQUEST_ARTICLES_TABLE,
        payload: currentCategory.id,
      });
    }
  } catch (e) {
    createError(e);
  }
}

function* fetchCategoryArticle({
  payload,
}: FetchArticlesTableCategoryRequestAction) {
  try {
    const category: CategoryArticle = yield fetchCategory(payload);
    yield put(fetchArticlesTableCategorySuccess(category));
  } catch (e) {
    createError(e);
  }
}

function* organizationsFetch({
  payload,
}: FetchOrganizationsRequestArticleTableAction) {
  try {
    const organizations: Organization[] = yield call(
      request.fetchOrganizations,
      payload
    );
    yield put(fetchOrganizationsSuccessArticleTable(organizations));
  } catch (e) {
    createError(e);
  }
}

function* articlesRightTableFetch({
  payload,
}: ReturnType<typeof fetchArticlesRightArticlesTableRequest>) {
  try {
    const filter: ReturnType<typeof getFilterArticlesRightTable> = yield select(
      getFilterArticlesRightTable
    );
    const { pageSize }: ReturnType<typeof getPaginationArticlesRightTable> =
      yield select(getPaginationArticlesRightTable);
    yield put(loadingShowRightArticlesTable());
    const articles: ResponseWithMeta<Article[]> = yield call(
      request.fetchArticles,
      payload?.page,
      pageSize,
      'title_desc',
      filter
    );
    yield put(
      payload?.updateType === 'update'
        ? fetchArticlesSuccessRightArticlesTable(articles)
        : fetchArticlesJoinSuccessRightArticlesTable(articles)
    );
    yield put(loadingHideRightArticlesTable());
  } catch (e) {
    yield put(loadingHideRightArticlesTable());
    createError(e);
  }
}

function* organizationsRightTableFetch({
  payload,
}: FetchOrganizationsRequestRightArticlesTableAction) {
  try {
    yield put(organizationsLoadingShowRightArticlesTable());
    const organizations: Organization[] = yield call(
      request.fetchOrganizations,
      payload
    );
    yield put(fetchOrganizationsSuccessRightArticlesTable(organizations));
    yield put(organizationsLoadingHideRightArticlesTable());
  } catch (e) {
    yield put(organizationsLoadingHideRightArticlesTable());
    createError(e);
  }
}

function* fetchCategories({
  payload,
}: ReturnType<typeof fetchCategoriesRequestRightArticlesTable>) {
  try {
    yield put(categoriesLoadingShowRightArticlesTable());
    const categories: CategoryArticle[] = yield call(
      request.fetchCategoriesRequest,
      payload
    );
    yield put(fetchCategoriesSuccessRightArticlesTable(categories));
    yield put(categoriesLoadingHideRightArticlesTable());
  } catch (e) {
    yield put(categoriesLoadingHideRightArticlesTable());
    yield put(fetchCategoriesSuccessRightArticlesTable([]));
    createError(e);
  }
}

function* createCategoryFunction({
  payload,
}: ReturnType<typeof createCategoryRequestRightArticlesTable>) {
  try {
    const organization: SelectOption | null = yield select(
      getSelectedOrganizationArticlesRightTable
    );
    yield call(createCategory, payload);
    yield put(fetchCategoriesRequestRightArticlesTable(organization?.value));
  } catch (e) {
    createError(e);
  }
}

function* editCategoryFunction({
  payload,
}: ReturnType<typeof editCategoryRequestRightArticlesTable>) {
  try {
    const organization: SelectOption | null = yield select(
      getSelectedOrganizationArticlesRightTable
    );
    yield call(editCategory, payload);
    yield put(fetchCategoriesRequestRightArticlesTable(organization?.value));
  } catch (e) {
    createError(e);
  }
}

function* deleteCategoryFunction({
  payload,
}: ReturnType<typeof deleteCategoryRequestRightArticlesTable>) {
  try {
    const organization: SelectOption<string> | null = yield select(
      getSelectedOrganizationArticlesRightTable
    );
    yield call(deleteCategory, payload);
    yield put(fetchCategoriesRequestRightArticlesTable(organization?.value));
  } catch (e) {
    createError(e);
  }
}

function* editArticleFunction({
  payload,
}: ReturnType<typeof editArticleRequestRightArticlesTable>) {
  try {
    const organization: SelectOption<string> | null = yield select(
      getSelectedOrganizationArticlesRightTable
    );
    yield call(editArticle, payload);
    yield put(fetchCategoriesRequestRightArticlesTable(organization?.value));
  } catch (e) {
    createError(e);
  }
}

function* deleteArticleFunction({
  payload,
}: ReturnType<typeof deleteArticleRequestRightArticlesTable>) {
  try {
    const organization: SelectOption<string> | null = yield select(
      getSelectedOrganizationArticlesRightTable
    );
    yield call(deleteArticle, payload);
    yield put(fetchCategoriesRequestRightArticlesTable(organization?.value));
  } catch (e) {
    createError(e);
  }
}

export function* articlesTableSaga(): Generator<StrictEffect> {
  yield takeEvery(
    ArticlesTable.FETCH_ARTICLES_REQUEST_ARTICLES_TABLE,
    articlesTableFetch
  );
  yield takeEvery(
    ArticlesTable.FETCH_CATEGORY_REQUEST_ARTICLES_TABLE,
    fetchCategoryArticle
  );
  yield takeEvery(
    ArticlesTable.DELETE_ARTICLE_BY_ID_REQUEST_ARTICLES_TABLE,
    deleteArticleTable
  );
  yield takeEvery(
    ArticlesTable.FETCH_ORGANIZATIONS_REQUEST_ARTICLES_TABLE,
    organizationsFetch
  );
  yield takeEvery(
    ArticlesTable.FETCH_REQUEST_ARTICLES_RIGHT_ARTICLES_TABLE,
    articlesRightTableFetch
  );
  yield takeEvery(
    ArticlesTable.FETCH_ORGANIZATIONS_REQUEST_RIGHT_ARTICLES_TABLE,
    organizationsRightTableFetch
  );
  yield takeEvery(
    ArticlesTable.FETCH_CATEGORIES_REQUEST_RIGHT_ARTICLES_TABLE,
    fetchCategories
  );
  yield takeEvery(
    ArticlesTable.CREATE_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE,
    createCategoryFunction
  );
  yield takeEvery(
    ArticlesTable.EDIT_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE,
    editCategoryFunction
  );
  yield takeEvery(
    ArticlesTable.DELETE_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE,
    deleteCategoryFunction
  );
  yield takeEvery(
    ArticlesTable.EDIT_ARTICLE_REQUEST_RIGHT_ARTICLES_TABLE,
    editArticleFunction
  );
  yield takeEvery(
    ArticlesTable.DELETE_ARTICLE_REQUEST_RIGHT_ARTICLES_TABLE,
    deleteArticleFunction
  );
}
