import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ToggleSwitch, Typography, TypographyVariants } from 'components';
import {
  setIsContrastTheme,
  setIsSystemTheme,
  setTheme,
} from 'core/ducks/actions';
import {
  getContrastTheme,
  getSystemTheme,
  getTheme,
} from 'core/ducks/selectors';
import { Theme, ThemeActive } from 'core/types';

import styles from './InterfaceSettings.module.scss';

export const InterfaceSettings = () => {
  const dispatch = useDispatch();

  const theme = useSelector(getTheme);
  const systemTheme = useSelector(getSystemTheme);
  const contrastTheme = useSelector(getContrastTheme);

  const isContrastTheme = contrastTheme === ThemeActive.ON;
  const isSystemTheme = systemTheme === ThemeActive.ON;
  const isDarkTheme =
    theme === (!isContrastTheme ? Theme.DARK : Theme.DARK_CONTRAST);

  const changeThemeHandler = useCallback(() => {
    if (isDarkTheme) {
      return dispatch(
        setTheme(!isContrastTheme ? Theme.LIGHT : Theme.LIGHT_CONTRAST)
      );
    }
    return dispatch(
      setTheme(!isContrastTheme ? Theme.DARK : Theme.DARK_CONTRAST)
    );
  }, [isDarkTheme, isContrastTheme, dispatch, setTheme]);

  const changeIsSystemThemeHandler = useCallback(() => {
    if (isSystemTheme) {
      return dispatch(setIsSystemTheme(ThemeActive.OFF));
    }
    return dispatch(setIsSystemTheme(ThemeActive.ON));
  }, [isSystemTheme, dispatch, setIsSystemTheme]);

  const changeIsContrastThemeHandler = useCallback(() => {
    if (isContrastTheme) {
      dispatch(setIsContrastTheme(ThemeActive.OFF));
      dispatch(setTheme(isDarkTheme ? Theme.DARK : Theme.LIGHT));
      return;
    }
    dispatch(setIsContrastTheme(ThemeActive.ON));
    dispatch(
      setTheme(isDarkTheme ? Theme.DARK_CONTRAST : Theme.LIGHT_CONTRAST)
    );
  }, [isContrastTheme, isDarkTheme, dispatch, setIsContrastTheme]);

  return (
    <div className={styles.interfaceSettings}>
      <Typography
        variant={TypographyVariants.h4}
        className={styles.interfaceSettings__title}
      >
        Интерфейс
      </Typography>
      <div className={styles.interfaceSettings__switch}>
        <ToggleSwitch
          label="Темный режим"
          disabled={isSystemTheme}
          checked={isDarkTheme}
          onChange={changeThemeHandler}
          classNameLabel={styles.interfaceSettings__switchLabel}
        />
      </div>
      <div className={styles.interfaceSettings__switch}>
        <ToggleSwitch
          label="Использовать системную тему"
          checked={isSystemTheme}
          onChange={changeIsSystemThemeHandler}
          classNameLabel={styles.interfaceSettings__switchLabel}
        />
      </div>
      <div className={styles.interfaceSettings__switch}>
        <ToggleSwitch
          label="Повысить контрастность"
          checked={isContrastTheme}
          onChange={changeIsContrastThemeHandler}
          classNameLabel={styles.interfaceSettings__switchLabel}
        />
      </div>
    </div>
  );
};
