import { getUrl } from 'utils';

export const endpoints = {
  getArticlesFilter: () => getUrl('v1/article/filter'),
  getOrganizations: () => getUrl('v1/user-access/organizations'),
  fetchCategories: (organizationId?: string) => {
    const endOfUrl = organizationId ? `/${organizationId}` : '';
    return getUrl(`v1/category/parent${endOfUrl}`);
  },
};
