import { nanoid } from 'nanoid';

import { Alert, AlertTypes } from 'core/types';

export const createSuccessAlert = (message: string): Alert => {
  return {
    id: nanoid(),
    type: AlertTypes.SUCCESS,
    message,
  };
};

export const createErrorAlert = (message: string): Alert => {
  return {
    id: nanoid(),
    type: AlertTypes.ERROR,
    message,
  };
};

export const createWarningAlert = (message: string): Alert => {
  return {
    id: nanoid(),
    type: AlertTypes.WARNING,
    message,
  };
};
