import cn from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ArrowLeftIcon,
  ArrowUpWithLine,
  CloseIcon,
  LineIcon,
} from 'assets/icons';
import { IconButtonWrapper, Loader } from 'components';
import { Article } from 'core/types/knowledgeBase';
import { fetchArticleSuccess } from 'features/UserAssistance/ducks';
import { getArticleLoading } from 'features/UserAssistance/ducks/selectors';

import { UserAssistanceArticleDetailContent } from '../UserAssistanceArticleDetailContent';
import { UserAssistanceArticleDetailHeader } from '../UserAssistanceArticleDetailHeader';

import styles from './UserAssistanceArticleDetail.module.scss';

interface UserAssistanceArticleDetailProps {
  isOpen: boolean;
  toggleIsOpen(): void;
  article?: Article;
  toggleIsOpenAll(): void;
  toggleIsHiddenParent(): void;
}

export const UserAssistanceArticleDetail: FC<
  UserAssistanceArticleDetailProps
> = ({
  isOpen,
  toggleIsOpen,
  article,
  toggleIsHiddenParent,
  toggleIsOpenAll,
}) => {
  const dispatch = useDispatch();

  const [isHidden, setIsHidden] = useState<boolean>(false);

  const loading = useSelector(getArticleLoading);

  const toggleIsHidden = () => {
    setIsHidden((prevState) => !prevState);
  };

  const toggleIsHiddenAll = () => {
    toggleIsHiddenParent();
    toggleIsHidden();
  };

  useEffect(() => {
    if (!isOpen && isHidden) {
      setIsHidden(false);
    }
  }, [isOpen, isHidden]);

  useEffect(() => {
    if (!isOpen) {
      dispatch(fetchArticleSuccess(undefined));
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const loader = loading && <Loader />;

  const content = !loading && (
    <>
      <UserAssistanceArticleDetailHeader
        toggleIsOpen={toggleIsOpen}
        article={article}
      />
      <UserAssistanceArticleDetailContent article={article} />
    </>
  );

  const hideIcon = isHidden ? (
    <ArrowUpWithLine
      className={styles.userAssistanceArticleDetail__headerCloseIcon}
    />
  ) : (
    <LineIcon className={styles.userAssistanceArticleDetail__headerCloseIcon} />
  );

  return (
    <div
      className={cn(styles.userAssistanceArticleDetail, {
        [styles.userAssistanceArticleDetail_hidden]: isHidden,
      })}
      onMouseDown={toggleIsOpen}
      aria-hidden="true"
    >
      <div
        className={cn(styles.userAssistanceArticleDetail__container, {
          [styles.userAssistanceArticleDetail__container_hidden]: isHidden,
        })}
        onMouseDown={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        <div className={styles.userAssistanceArticleDetail__header}>
          <h3 className={styles.userAssistanceArticleDetail__headerTitle}>
            Помощь
          </h3>
          <div className={styles.userAssistanceArticleDetail__buttonsWrapper}>
            <IconButtonWrapper
              className={styles.userAssistanceArticleDetail__headerCloseButton}
              onClick={toggleIsHiddenAll}
              icon={hideIcon}
            />
            <IconButtonWrapper
              className={styles.userAssistanceArticleDetail__headerCloseButton}
              onClick={toggleIsOpenAll}
              icon={
                <CloseIcon
                  className={
                    styles.userAssistanceArticleDetail__headerCloseIcon
                  }
                />
              }
            />
            <IconButtonWrapper
              className={
                styles.userAssistanceArticleDetail__headerCloseButtonMobile
              }
              onClick={toggleIsOpen}
              icon={
                <ArrowLeftIcon
                  className={
                    styles.userAssistanceArticleDetail__headerCloseIcon
                  }
                />
              }
            />
          </div>
        </div>
        <div
          className={cn(styles.userAssistanceArticleDetail__content, {
            [styles.userAssistanceArticleDetail__content_loading]: loading,
          })}
        >
          {loader}
          {content}
        </div>
      </div>
    </div>
  );
};
