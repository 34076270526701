import { Editor as EditorType } from '@ckeditor/ckeditor5-core';
import { ChangeEvent, FC, ReactNode } from 'react';

import { CustomFieldType, ValueType } from 'components';
import { KeyValueOption } from 'types/models/meta';

import {
  Organization,
  OrganizationShort,
  OrganizationTypeForCustomField,
} from './types/organization';
import { System } from './types/system';
import { User } from './types/user';
import { WorkGroup } from './types/workGroup';

export type Nullable<T> = T | null;

export type NString = Nullable<string>;

export type NNumber = Nullable<number>;

export type NBoolean = Nullable<boolean>;

export enum ScreenWidth {
  MOBILE_SMALL = 'MOBILE_SMALL',
  MOBILE = 'MOBILE',
  TABLET_SMALL = 'TABLET_SMALL',
  TABLET = 'TABLET',
  DESKTOP_SMALL = 'DESKTOP_SMALL',
  DESKTOP = 'DESKTOP',
}

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
  LIGHT_CONTRAST = 'light-contrast',
  DARK_CONTRAST = 'dark-contrast',
}

export enum ThemeActive {
  ON = 'on',
  OFF = 'off',
}

export enum AlertTypes {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export enum ActionForAlertTypes {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  CREATE = 'CREATE',
}

export enum OrganizationType {
  CUSTOMER = 'CUSTOMER',
  SERVICE = 'SERVICE',
  PARTNER = 'PARTNER',
}

export enum ContractType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  SUPPLEMENTARY_AGREEMENT = 'SUPPLEMENTARY_AGREEMENT',
}

export enum ContractStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'HIGH',
  PLANNED = 'LOW',
  NOT_SIGNED = 'NOT_SIGNED',
}

export enum SupportType {
  LICENSED = 'LICENSED',
  EXTENDED = 'EXTENDED',
}

export enum TicketStatus {
  CLOSE = 'CLOSE',
  NEW = 'NEW',
  PENDING_CLOSURE = 'PENDING_CLOSURE',
  WORK = 'WORK',
  WAITING_INFO = 'WAITING_INFO',
  REOPEN = 'REOPEN',
}

export enum FieldsType {
  organizations = 'organizations',
  systems = 'systems',
  ticketTypes = 'ticketTypes',
  ticketPriorities = 'ticketPriorities',
  environments = 'environments',
  clients = 'clients',
  status = 'status',
}

export enum RoleType {
  ROLE_IN_GROUP = 'ROLE_IN_GROUP',
  ROLE_IN_SYSTEM = 'ROLE_IN_SYSTEM',
}

export enum Priority {
  NONE = 'NONE',
  LOW = 'LOW',
  MIDDLE = 'MIDDLE',
  HIGH = 'HIGH',
  VERY_HIGH = 'VERY_HIGH',
}

export type Alert = {
  id: string;
  message: string | ReactNode;
  type: AlertTypes;
};

type CKEditorProps = {
  editor: EditorType;
  data: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any;
  onReady: (editor: EditorType) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>, editor: EditorType) => void;
};

export type EditorRefType = {
  CKEditor: FC<CKEditorProps>;
  Editor: EditorType;
};

export type LoaderProps = {
  withLoader?: boolean;
  isLoading?: boolean;
};

// Attributes

export type Attribute = {
  id: string;
  isActive: boolean;
  attribute: ValueType<string>;
  value: ValueType<string>;
};

export type Attributes = {
  organizations: KeyValueOption[];
  systems: KeyValueOption[];
  environments: KeyValueOption[];
  clients: KeyValueOption[];
  ticketTypes: KeyValueOption[];
  ticketPriorities: KeyValueOption[];
};

// Version

export type Version = {
  id: string;
  version: string;
};

// Contract

export type ContractNotificationConfig = {
  contractExpireIsEnable?: boolean;
  notifyBeforeExpire?: number;
};

export type ContractPermission = {
  storageAccessible?: boolean;
};

export type Contract = {
  id: string;
  title: string;
  description?: string;
  type: ContractType;
  status: ContractStatus;
  number?: string;
  openDate: string;
  endDate: string;
  organizationId?: string;
  organization?: OrganizationShort;
  organizationTitle?: string;
  partnerOrganization?: KeyValueOption;
  systemList?: System[];
  orgType?: string;
  orgDomain?: string;
  orgDescription?: string;
  orgAddress?: string;
  userList?: User[];
  workGroupList?: WorkGroup[];
  dateCreate?: string;
  systemTitle?: string;
  contractNotificationConfig?: ContractNotificationConfig;
  contractPermission?: ContractPermission;
  mainContractId?: string;
  supportType?: SupportType;
};

// CustomField

export type ValueCustomField = {
  text: NString;
  date: NString;
  flag: NBoolean;
};

export type CustomField = {
  id: string;
  title?: string;
  customFieldType?: CustomFieldType;
  organization?: Partial<Pick<Organization, 'id' | 'title'>>;
  accessType?: OrganizationTypeForCustomField;
  system?: Partial<Pick<System, 'id' | 'title'>>;
  required?: boolean;
};

export type CustomTicketFields = [
  CustomFieldToRequest[],
  CustomFieldToRequest[]
];

export type CustomFieldToRequest = CustomField &
  Partial<ValueCustomField> & {
    ticketId?: string;
    customFieldId?: string;
  };

// Environments

export type Environment = {
  id: string;
  name: string;
  description: string;
  deleted?: boolean;
};

export type EnvironmentSetting = {
  active: boolean;
  environmentId: string;
  name: string;
};

export type EnvironmentSettingUpdate = {
  environmentId: string;
  active: boolean;
};

export type EnvironmentForSystem = {
  systemId: string;
  environmentId?: string;
};

export interface EnvironmentsWithStatus {
  environmentsWithStatus: EnvironmentSettingUpdate[];
}

export interface EnvironmentsFilter {
  id?: string;
  name?: string;
  systemId?: string;
  description?: string;
  isActive?: boolean;
}

// Status

export type Status = {
  id: string;
  name: string;
  isComment: boolean;
  isNotifications: boolean;
  isSLA: boolean;
  color: string;
  defaultStatus?: TicketStatus;
};

export type TicketStatusesRequest = {
  excludeStatuses?: TicketStatus[];
  systemIds?: string[];
  nextToIds?: string[];
  typeIds?: string[];
};

// TicketType

export type TicketType = {
  id: string;
  title: string;
  value: string;
};

export type TicketTypeSettings = {
  ticketTypeId: string;
  active?: boolean;
  title: string;
};
