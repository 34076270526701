import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { getValueFromValueType, TabItem } from 'components';
import { ActionList } from 'core/types/auth';
import { getIsAccessedAction, getIsGroupSpecialist } from 'features/Auth';

import {
  SLIDE_RADIO_TABS_FOR_ALL,
  SLIDE_RADIO_TABS_FOR_MANAGER,
  SLIDE_RADIO_TABS_FOR_ORG,
} from '../constants';
import {
  fetchTicketsRequest,
  fetchTicketsStatisticRequest,
  resetCurrentState,
} from '../ducks/CurrentState';
import {
  getCurrentStateTickets,
  getTicketsStatistic,
} from '../ducks/CurrentState/selectors';
import {
  downloadTicketsRequest,
  fetchOrganizationsRequest,
  fetchSystemsRequest,
  resetDesktopState,
  setDesktopFilters,
} from '../ducks/Desktop';
import { getIsDesktopTicketsLoading } from '../ducks/Desktop/selectors';
import { CurrentStateFormData } from '../types';

export const useCurrentStateForm = () => {
  const dispatch = useDispatch();

  const [radioTabs, setRadioTabs] = useState<TabItem[]>([]);

  const isGroupSpecialist = useSelector(getIsGroupSpecialist);
  const isAccessToCreateReportByOrg = useSelector(
    getIsAccessedAction(ActionList.CreateReportByOrganization)
  );

  const tickets = useSelector(getCurrentStateTickets);
  const ticketsStatistic = useSelector(getTicketsStatistic);
  const isDownload = useSelector(getIsDesktopTicketsLoading);

  const downloadTicketsData = ticketsStatistic?.downloadInfo;

  const { watch, control, resetField, setValue } =
    useForm<CurrentStateFormData>({
      mode: 'onChange',
      defaultValues: {
        infoType: 'workGroup',
      },
    });

  const { infoType, organizationId, systemId, workGroupId } = watch();

  const isOrganizationType = infoType === 'organization';

  useEffect(() => {
    if (isGroupSpecialist && !isAccessToCreateReportByOrg) {
      setRadioTabs(SLIDE_RADIO_TABS_FOR_MANAGER);
      setValue('infoType', 'workGroup');
    }
    if (!isGroupSpecialist && isAccessToCreateReportByOrg) {
      setRadioTabs(SLIDE_RADIO_TABS_FOR_ORG);
      setValue('infoType', 'organization');
    }
    if (isGroupSpecialist && isAccessToCreateReportByOrg) {
      setRadioTabs(SLIDE_RADIO_TABS_FOR_ALL);
      setValue('infoType', 'workGroup');
    }
  }, [isGroupSpecialist, isAccessToCreateReportByOrg]);

  useEffect(() => {
    if (isOrganizationType) {
      dispatch(fetchOrganizationsRequest());
    }
  }, [isOrganizationType]);

  useEffect(() => {
    if (organizationId && !Array.isArray(organizationId)) {
      dispatch(fetchSystemsRequest(organizationId?.value));
    }
  }, [organizationId]);

  useEffect(() => {
    if (organizationId || systemId || workGroupId) {
      dispatch(
        setDesktopFilters({
          workgroupId: getValueFromValueType(workGroupId),
          organizationId: getValueFromValueType(organizationId),
          systemId: getValueFromValueType(systemId),
          onlyActualTickets: true,
        })
      );
      dispatch(fetchTicketsRequest());
      dispatch(fetchTicketsStatisticRequest());
    }
  }, [organizationId, systemId, workGroupId]);

  useEffect(() => {
    return () => {
      dispatch(resetCurrentState());
      dispatch(resetDesktopState());

      resetField('workGroupId');
      resetField('organizationId');
      resetField('systemId');
      resetField('infoType');
    };
  }, []);

  useEffect(() => {
    dispatch(resetCurrentState());
    dispatch(resetDesktopState());

    resetField('workGroupId');
    resetField('organizationId');
    resetField('systemId');
  }, [infoType]);

  const handleDownload = useCallback(() => {
    if (downloadTicketsData) {
      dispatch(
        downloadTicketsRequest({
          path: downloadTicketsData?.path,
          fileName: downloadTicketsData?.fileName,
        })
      );
    }
  }, [tickets, downloadTicketsData]);

  return {
    state: {
      radioTabs,
      isOrganizationType,
      tickets,
      ticketsStatistic,
      isDownload,
    },
    methods: {
      currentStateControl: control,
      handleDownload,
      setCurrentStateValue: setValue,
    },
  };
};
