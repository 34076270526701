import { ValueType } from 'components';
import { OrganizationType } from 'core/types';

import { Organization } from './organization';
import { Role } from './roles';
import { WorkGroup } from './workGroup';

export type User = {
  id: string;
  firstName: string;
  lastName?: string;
  middleName?: string;
  dateBirth?: string;
  email?: string;
  phoneNumber?: string;
  login?: string;
  password?: string;
  workGroupList?: WorkGroup[];
  description?: string;
  organization?: Organization;
  roles?: Role[];
  positions?: unknown;
  dateCreate?: string;
  work?: number;
  closed?: number;
  expired?: number;
  percentFromMaxDecision?: number;
  avgDecisionSeconds?: number;
  requireUpdatePassword?: boolean;
};

export type UsersFilterToRequest = {
  login?: string;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  email?: string;
  phoneNumber?: string;
  organizationId?: string;
  idsUsersDelete?: Array<string>;
  idsWorkGroupsDelete?: Array<string>;
  fullName?: string;
  organizationType?: OrganizationType;
};

export type UsersFilter = Omit<UsersFilterToRequest, 'organizationId'> & {
  fullName?: string;
  organizationId?: ValueType<string>;
};

export enum TableUsersTypes {
  FULL = 'FULL',
  FROM_GROUPS = 'FROM_GROUPS',
  ADD_MODAL = 'ADD_MODAL',
  FROM_DESKTOP = 'FROM_DESKTOP',
}

export interface CreateUserData
  extends Pick<
    User,
    | 'firstName'
    | 'lastName'
    | 'middleName'
    | 'description'
    | 'login'
    | 'password'
    | 'email'
    | 'phoneNumber'
    | 'id'
    | 'requireUpdatePassword'
  > {
  organizationId?: ValueType<string>;
}
