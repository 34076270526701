import { call } from 'redux-saga/effects';

import { Article } from 'core/types/knowledgeBase';
import { createError } from 'utils';

import { ArticleCreateData, CategoryArticle } from '../types';

import { request } from './api/requests';

export function* createCategory(data: Partial<CategoryArticle>) {
  try {
    yield call(request.createCategoryRequest, data);
  } catch (e) {
    createError(e);
  }
}

export function* editCategory(data: Partial<CategoryArticle>) {
  try {
    yield call(request.editCategoryRequest, data);
  } catch (e) {
    createError(e);
  }
}

export function* deleteCategory(id: string) {
  try {
    yield call(request.deleteCategoryRequest, id);
  } catch (e) {
    createError(e);
  }
}

export function* articleCreate(data: ArticleCreateData) {
  let article: Article | undefined;
  try {
    article = yield call(request.createArticleRequest, {
      ...data,
      isSolution: false,
    });
  } catch (e) {
    createError(e);
  }
  return article;
}

export function* editArticle(data: Partial<Article>) {
  try {
    yield call(request.editArticleRequest, data);
  } catch (e) {
    createError(e);
  }
}

export function* deleteArticle(id: string) {
  try {
    yield call(request.deleteArticleRequest, id);
  } catch (e) {
    createError(e);
  }
}

export function* fetchCategory(id: string) {
  let category: CategoryArticle | undefined;
  try {
    category = yield call(request.fetchCategoryRequest, id);
  } catch (e) {
    createError(e);
  }
  return category;
}

export function* fetchArticle(id: string) {
  let article: Article | undefined;
  try {
    article = yield call(request.fetchArticleRequest, id);
  } catch (e) {
    createError(e);
  }
  return article;
}
