import cn from 'clsx';
import { MouseEvent, ReactElement } from 'react';

import { CloseIcon } from 'assets/icons';
import { getListingInputValue } from 'components';

import { SelectOption } from '../../types';

import styles from './MultiValueItem.module.scss';

export interface MultiValueContainerProps<T> {
  selected: Array<SelectOption<T>>;
  onChange: (
    option: SelectOption<T>
  ) => (event: MouseEvent<HTMLButtonElement>) => void;
  isChip?: boolean;
  isMultiSearch?: boolean;
  disabled?: boolean;
}

export const MultiValueContainer = <T,>({
  selected = [],
  onChange,
  isChip,
  isMultiSearch,
  disabled,
}: MultiValueContainerProps<T>): ReactElement => {
  return (
    <>
      {isMultiSearch && (
        <input
          className={cn(styles.multiValueItem__search, {
            [styles.multiValueItem__search_disabled]: disabled,
          })}
          type="text"
          value={getListingInputValue(selected)}
          readOnly
        />
      )}
      {!isMultiSearch &&
        selected.map((option: SelectOption<T>, index) => (
          <span
            className={cn(styles.multiValueItem__option, {
              [styles.multiValueItem__option_chip]: isChip,
            })}
            key={option.title}
          >
            <span
              className={cn({
                [styles.multiValueItem__title_chip]: isChip,
              })}
            >
              {option.title}
            </span>
            {!isChip && index < selected.length - 1 ? (
              <span className={styles.multiValueItem__comma}>,</span>
            ) : null}
            {isChip && (
              <button
                onClick={onChange(option)}
                className={styles.multiValueItem__deleteButton}
              >
                <CloseIcon className={styles.multiValueItem__deleteIcon} />
              </button>
            )}
          </span>
        ))}
    </>
  );
};
