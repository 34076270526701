import cn from 'clsx';
import { FC, useMemo } from 'react';

import { ErrorRoundIcon } from 'assets/icons';
import { TableTicketsTypes } from 'core/types/ticket';
import {
  PieChartPriority,
  PieChartStatus,
  TicketsDashboard,
  TicketsDropContent,
} from 'features/Desktop/components';
import { useCurrentStateForm } from 'features/Desktop/hooks';
import { TicketsTableContainer } from 'features/Tickets';

import styles from './DesktopCurrentState.module.scss';

interface DesktopCurrentStateProps {
  className?: string;
}

export const DesktopCurrentState: FC<DesktopCurrentStateProps> = ({
  className,
}) => {
  const {
    state: { tickets, ticketsStatistic, isDownload },
    methods: { handleDownload },
  } = useCurrentStateForm();

  const tableTitle = useMemo(
    () => (
      <div className={styles.desktop__titleWrapper}>
        <span className={styles.desktop__title}>Просрочено</span>
        {Boolean(tickets?.length) && <ErrorRoundIcon />}
      </div>
    ),
    [tickets]
  );

  const downloadTickets = tickets?.length ? (
    <TicketsDropContent
      isDownload={isDownload}
      handleDownload={handleDownload}
    />
  ) : undefined;

  return (
    <div className={cn(styles.desktop, className)}>
      <div className={styles.desktop__topContainer}>
        <TicketsDashboard
          className={styles.desktop__ticketsDashboard}
          ticketsStatistic={ticketsStatistic}
        />
        <TicketsTableContainer
          tableType={TableTicketsTypes.FROM_DESKTOP}
          title={tableTitle}
          withOutTabs
          withFilter={false}
          dropContent={downloadTickets}
          className={styles.desktop__ticketsTable}
        />
      </div>
      <div className={styles.desktop__bottomContainer}>
        <PieChartStatus
          className={styles.desktop__pieChart}
          ticketsStatistic={ticketsStatistic}
        />
        <PieChartPriority
          className={styles.desktop__pieChart}
          ticketsStatistic={ticketsStatistic}
        />
      </div>
    </div>
  );
};
