import { KeyValueOption } from 'types/models/meta';

import { CustomConditions, DefaultConditions, Trigger } from '../types';

import { getKeyFromKeyValue } from './getKeyFromKeyValue';

interface Args {
  isEnable: boolean;
  selectedTrigger: Trigger;
  customConditions: CustomConditions[];
  defaultConditions: DefaultConditions[];
  targetStatus: KeyValueOption;
}

export const getPreparedDataForUpdate = ({
  isEnable,
  selectedTrigger,
  customConditions,
  defaultConditions,
  targetStatus,
}: Args) => {
  const preparedCustomConditions = customConditions.map(
    ({ conditionType, customField, textValue, flagValue, minutes }) => {
      return {
        conditionType,
        customFieldId: customField.key,
        textValue,
        flagValue,
        minutes,
      };
    }
  );

  return {
    title: selectedTrigger.title,
    isEnable,
    customConditionRequests: preparedCustomConditions,
    defaultConditions,
    targetStatus: targetStatus.key,
    organization: selectedTrigger.organization.key,
    system: selectedTrigger.system.key,
    ticketTypes: getKeyFromKeyValue(selectedTrigger.ticketTypes),
    ticketPriorities: getKeyFromKeyValue(selectedTrigger.ticketPriorities),
    environments: getKeyFromKeyValue(selectedTrigger.environments),
    clients: getKeyFromKeyValue(selectedTrigger.clients),
    status: selectedTrigger.status.key,
  };
};
