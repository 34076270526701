import cn from 'clsx';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input/input';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Accordion,
  Card,
  getNormalizedPhoneNumber,
  Input,
  PhoneInput,
  TextArea,
  ToggleSwitch,
  Typography,
  TypographyVariants,
} from 'components';
import { BottomButtonsBlock } from 'core/components';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { ApproveOrCancel } from 'core/modals';
import { ActionList } from 'core/types/auth';
import { CreateUserData, User } from 'core/types/user';
import {
  getIsAccessedAction,
  getIsClient,
  getUserWithOrganization,
} from 'features/Auth';
import { OrganizationSelect } from 'features/Organizations';
import { DEFAULT_USER_FORM_VALUES } from 'features/User/constants';
import { useUserId } from 'features/User/hooks';
import { getPreparedUserValues } from 'features/User/utils';
import {
  checkIsExistEmailRequest,
  checkIsExistEmailSuccess,
  checkIsExistLoginRequest,
  checkIsExistLoginSuccess,
  checkIsExistPhoneRequest,
  createUser,
  editUser,
  getIsExistEmail,
  getIsExistLogin,
  getIsExistPhone,
  getUser,
} from 'features/Users';
import { RouterHref } from 'routes/routerHref';
import { resetActiveElementFocus } from 'utils';

import styles from './UserForm.module.scss';

interface UserFormProps {
  className?: string;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export const UserForm: FC<UserFormProps> = ({ className }) => {
  const user = useSelector(getUser);
  const isAccessToCreateUser = useSelector(
    getIsAccessedAction(ActionList.CreateUser)
  );
  const isExistLogin = useSelector(getIsExistLogin);
  const isExistEmail = useSelector(getIsExistEmail);
  const isExistPhone = useSelector(getIsExistPhone);
  const isClient = useSelector(getIsClient);
  const organization = useSelector(getUserWithOrganization);
  const mainLayoutSticky = document.getElementById('mainLayoutSticky');

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isMobileAll = isMobile || isMobileSmall;

  const userId = useUserId();

  const isEditMode = !!user?.id && !!userId;

  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState<boolean>(false);

  const toggleModal = () => setIsModal(!isModal);

  const { push } = useHistory();

  const defaultValues = useMemo(
    () => (isEditMode ? getPreparedUserValues(user) : DEFAULT_USER_FORM_VALUES),
    [isEditMode, user]
  );

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm<CreateUserData>({
    mode: 'onChange',
    defaultValues,
  });

  const currentFormValues = watch();

  const isExistEmailError = isEditMode
    ? isExistEmail && user?.email !== currentFormValues.email
    : isExistEmail;

  const isValidData =
    isDirty && isAccessToCreateUser && !isExistLogin && !isExistEmailError;

  const canCreateUser = isValidData && isValid;

  const cancEditUser = isValidData;

  const canSave = isEditMode ? cancEditUser : canCreateUser;

  const isExistLoginErrorMessage = isExistLogin
    ? 'Пользователь с таким логином уже существует'
    : '';
  const isExistEmailErrorMessage = isExistEmailError
    ? 'Пользователь с таким E-mail уже существует'
    : '';

  const getInitialOrgOption = () => {
    if (isEditMode && user?.organization) {
      return {
        value: user.organization.id || '',
        title: user.organization.title,
      };
    }
    if (!isEditMode && isClient && organization) {
      return {
        value: organization.id || '',
        title: organization.title,
      };
    }
    return null;
  };

  useEffect(() => {
    if (user && userId) {
      reset(getPreparedUserValues(user));
    }
    setValue('organizationId', getInitialOrgOption());
  }, [user, userId]);

  const normalizeData = (data?: User) => {
    const newUserData = {
      ...data,
      organizationId: data?.organization
        ? {
            title: data.organization.title,
            value: data.organization.id,
          }
        : null,
    };
    delete newUserData.id;
    delete newUserData.workGroupList;
    delete newUserData.organization;
    delete newUserData.positions;
    delete newUserData.roles;
    delete newUserData.dateCreate;
    delete newUserData.password;
    return newUserData;
  };

  const initialFormValues = normalizeData(user);

  useEffect(() => {
    return () => {
      dispatch(checkIsExistLoginSuccess(false));
      dispatch(checkIsExistEmailSuccess(false));
    };
  }, []);

  const isPhoneError = isEditMode
    ? isExistPhone &&
      initialFormValues.phoneNumber !== currentFormValues.phoneNumber
    : isExistPhone;

  const isExistPhoneErrorMessage = isPhoneError
    ? 'Пользователь с таким номером телефона уже существует'
    : '';

  const lastNameInputOptions = register('lastName', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Фамилия пользователя не может быть длиннее 100 символов.',
    },
  });

  const firstNameInputOptions = register('firstName', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Имя пользователя не может быть длиннее 100 символов.',
    },
  });

  const middleNameInputOptions = register('middleName', {
    maxLength: {
      value: 100,
      message: 'Отчество пользователя не может быть длиннее 100 символов.',
    },
  });

  const descriptionTextAreaOptions = register('description', {
    required: true,
    maxLength: {
      value: 255,
      message: 'Описание пользователя не может быть длиннее 255-ти символов.',
    },
  });

  const loginInputOptions = register('login', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Логин пользователя не может быть длиннее 100 символов.',
    },
    pattern: {
      value: /^[a-zA-Z0-9]+$/,
      message: 'Логин пользователя должен включать только латинские буквы',
    },
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      const login = e.target.value;
      if (login) {
        dispatch(checkIsExistLoginRequest(login));
      }
    },
  });

  const passwordInputOptions = register('password', {
    required: !isEditMode,
    minLength: {
      value: 8,
      message: 'Пароль пользователя должен содержать как минимум 8 символов.',
    },
    maxLength: {
      value: 128,
      message: 'Пароль пользователя не может быть длиннее 128 символов.',
    },
    pattern: {
      value:
        /(?=.*[0-9])(?=.*[-._!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|])(?=.*[a-z])(?=.*[A-Z])^[0-9a-zA-Z-._!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|]*$/g,
      message:
        'Пароль пользователя должен содержать хотя бы один спецсимвол, одну латинскую букву в верхнем и нижнем регистрах, одно число и не содержать пробелов',
    },
  });

  const emailInputOptions = register('email', {
    required: true,
    pattern: {
      value: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/,
      message: 'Введите корректный E-mail пользователя',
    },
    maxLength: {
      value: 100,
      message: 'Email пользователя не может быть длиннее 100 символов.',
    },
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      const email = e.target.value;
      if (email) {
        dispatch(checkIsExistEmailRequest(email));
      }
    },
  });

  const formSubmitHandler = handleSubmit((data) => {
    const { organizationId, phoneNumber } = data;
    const normalizedPhoneNumber = getNormalizedPhoneNumber(phoneNumber);

    const newUserData = {
      ...user,
      ...data,
      phoneNumber: normalizedPhoneNumber,
      organizationId,
    };

    if (!userId) {
      dispatch(createUser(newUserData));
      return;
    }
    dispatch(editUser({ ...newUserData, id: userId }));
    resetActiveElementFocus();
  });

  const onApproveModal = () => {
    push(RouterHref.AdminUsers);
    reset();
  };

  return (
    <div className={cn(styles.userForm, className)}>
      <Card className={styles.userForm__formHeader}>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.userForm__formHeaderTab}
        >
          Информация
        </Typography>
      </Card>
      <Card className={styles.userForm__formContent}>
        <form className={styles.userForm__form}>
          <Input
            {...lastNameInputOptions}
            label="Фамилия"
            disabled={!isAccessToCreateUser}
            error={!!errors.lastName}
            errorMessage={errors.lastName?.message}
            className={cn(
              styles.userForm__input,
              styles.userForm__input_oneOfThree
            )}
          />
          <Input
            {...firstNameInputOptions}
            label="Имя"
            disabled={!isAccessToCreateUser}
            error={!!errors.firstName}
            errorMessage={errors.firstName?.message}
            className={cn(
              styles.userForm__input,
              styles.userForm__input_oneOfThree
            )}
          />
          <Input
            {...middleNameInputOptions}
            label="Отчество"
            disabled={!isAccessToCreateUser}
            error={!!errors.middleName}
            errorMessage={errors.middleName?.message}
            className={cn(
              styles.userForm__input,
              styles.userForm__input_oneOfThree
            )}
          />
          <Controller
            control={control}
            name="organizationId"
            key="organizationId"
            rules={{
              required: true,
            }}
            render={({ field }) => {
              return (
                <OrganizationSelect
                  onChange={field.onChange}
                  disabled={!isAccessToCreateUser || isEditMode || isClient}
                  placeholder="Организация"
                  value={field.value}
                  className={cn(
                    styles.userForm__input,
                    styles.userForm__input_oneOfThree
                  )}
                />
              );
            }}
          />
          <TextArea
            {...descriptionTextAreaOptions}
            label="Описание"
            disabled={!isAccessToCreateUser}
            error={!!errors.description}
            errorMessage={errors.description?.message}
          />
          <Accordion title="Авторизация" className={styles.userForm__accordion}>
            <div className={styles.userForm__accordionContainer}>
              <Input
                {...loginInputOptions}
                label="Логин"
                disabled={!isAccessToCreateUser || Boolean(userId)}
                error={!!errors.login || isExistLogin}
                errorMessage={errors.login?.message || isExistLoginErrorMessage}
                className={cn(styles.userForm__input_oneOfThree, {
                  [styles.userForm__input]: isMobileAll,
                  [styles.userForm__input_mr]: !isMobileAll,
                })}
              />
              {!isEditMode && (
                <Input
                  {...passwordInputOptions}
                  label="Пароль"
                  disabled={!isAccessToCreateUser}
                  error={!!errors.password}
                  errorMessage={errors.password?.message}
                  isPassword
                  className={cn(styles.userForm__input_oneOfThree, {
                    [styles.userForm__input]: isMobileAll,
                    [styles.userForm__input_mr]: !isMobileAll,
                  })}
                />
              )}
              <Controller
                control={control}
                name="requireUpdatePassword"
                rules={{ deps: 'lastName' }}
                render={({ field }) => {
                  return (
                    <ToggleSwitch
                      label="Изменить пароль при первом входе"
                      checked={!!field.value}
                      onChange={field.onChange}
                      className={cn(
                        styles.userForm__input_oneOfThree,
                        styles.userForm__toggleSwitch
                      )}
                    />
                  );
                }}
              />
            </div>
          </Accordion>
          <Accordion
            title="Контактная информация"
            className={styles.userForm__accordion}
          >
            <div className={styles.userForm__accordionContainer}>
              <Input
                {...emailInputOptions}
                label="E-mail"
                disabled={!isAccessToCreateUser}
                error={!!errors.email || isExistEmailError}
                errorMessage={errors.email?.message || isExistEmailErrorMessage}
                className={cn(styles.userForm__input_oneOfThree, {
                  [styles.userForm__input]: isMobileAll,
                  [styles.userForm__input_mr]: !isMobileAll,
                })}
              />
              <Controller
                name="phoneNumber"
                key="phoneNumber"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => {
                    const country = parsePhoneNumber(value || '')?.country;
                    return (
                      isValidPhoneNumber(value || '', country) ||
                      'Некорректный номер'
                    );
                  },
                  maxLength: {
                    value: 100,
                    message:
                      'Номер телефона пользователя не может быть длиннее 100 символов.',
                  },
                }}
                render={({ field }) => (
                  <PhoneInput
                    type="tel"
                    id="phoneNumber"
                    isWithCountry
                    isEditMode={isEditMode}
                    value={field.value}
                    disabled={!isAccessToCreateUser}
                    errorMessage={
                      errors.phoneNumber?.message || isExistPhoneErrorMessage
                    }
                    onChange={(value) => {
                      field.onChange(value);
                      dispatch(checkIsExistPhoneRequest(value));
                    }}
                    className={styles.userForm__input_oneOfThree}
                  />
                )}
              />
            </div>
          </Accordion>
          <BottomButtonsBlock
            isOpen={canCreateUser && !isPhoneError}
            parentNode={mainLayoutSticky}
            onCancel={toggleModal}
            onSave={formSubmitHandler}
            disabledSubmit={!canSave}
          />
        </form>
      </Card>
      <ApproveOrCancel
        onApprove={onApproveModal}
        isModal={isModal}
        toggleModal={toggleModal}
        text={`Вы уверены, что хотите отменить ${
          userId ? 'редактирование' : 'создание'
        } пользователя?`}
      />
    </div>
  );
};
