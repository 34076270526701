import { ValueType } from 'components';
import {
  Contract,
  Environment,
  Priority,
  TicketType,
  Version,
} from 'core/types';
import { SystemData } from 'features/System/types';

import { Organization, OrganizationsFilterToRequest } from './organization';
import { ProcedureType } from './ticket';

export type System = {
  id: string;
  title: string;
  description: string;
  index: string;
  contractList?: Contract[];
  environmentList: Environment[];
  typeList: TicketType[];
  organization?: Organization;
  organizationId?: ValueType<string>;
  version?: Version;
  versionId?: string;
  versionTitle?: string;
  deleted?: boolean;
  activeContract?: Contract;
};

export type SystemsFilterToRequest = {
  title?: string;
  description?: string;
  versionTitle?: string;
  organizationId?: string;
  deleted?: boolean | null;
  contractTitle?: string;
  withOnlyActiveContract?: boolean;
  ticketType?: TicketType[];
  environmentIds?: string[];
} & Pick<OrganizationsFilterToRequest, 'organizationTypes'>;

export type SystemsFilter = Omit<
  SystemsFilterToRequest,
  'organizationId' | 'environmentIds'
> & {
  organizationId?: ValueType<string>;
  environmentIds?: ValueType<string>;
};

export type SystemPriority = {
  id: string;
  title: string;
  value: Priority;
  type: ProcedureType;
};

export enum TableSystemsTypes {
  FULL = 'FULL',
  FROM_CONTRACTS = 'FROM_CONTRACTS',
}

export interface SystemFormData {
  system: SystemData;
  ticketType: TicketType[];
  environment: Environment[];
}
