import { RenderTypes, TableColumns } from 'components';
import { TableSystemsTypes } from 'core/types/system';

export const TABLE_HEADER_FULL_FIELDS: TableColumns = [
  {
    title: 'Название',
    sortField: 'title',
    fieldName: 'title',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  { title: 'Описание', sortField: 'description', fieldName: 'description' },
  {
    title: 'Версия',
    sortField: 'version',
    fieldName: 'versionTitle',
    renderType: RenderTypes.CONDITION,
  },
  {
    title: 'Организация',
    sortField: 'organization',
    fieldName: 'organizationId',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  {
    title: 'Контракт',
    fieldName: 'activeContract',
  },
  {
    title: 'Тип тикета',
    fieldName: 'ticketType',
  },
  {
    title: 'Среда',
    fieldName: 'environment',
  },
];

export const TABLE_FIELDS_FROM_CONTRACTS: TableColumns = [
  {
    title: 'Название',
    fieldName: 'title',
    sortField: 'title',
    renderType: RenderTypes.CONDITION,
  },
  { title: 'Описание', fieldName: 'description', sortField: 'description' },
];

export const INITIAL_SYSTEMS_FILTER = {
  title: '',
  description: '',
  versionTitle: '',
  organizationId: null,
};

export const PAGINATION_RANGE_MAP: Record<
  TableSystemsTypes,
  number | undefined
> = {
  [TableSystemsTypes.FULL]: 1,
  [TableSystemsTypes.FROM_CONTRACTS]: 0,
};
