import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  convertFromDateFormat,
  convertToDateFormat,
  getValueFromValueType,
} from 'components';

import {
  downloadTicketsRequest,
  fetchOrganizationsRequest,
  fetchSystemsRequest,
  resetDesktopState,
  setDesktopFilters,
} from '../ducks/Desktop';
import { getIsDesktopTicketsLoading } from '../ducks/Desktop/selectors';
import {
  fetchTicketsRequest,
  fetchTicketsStatisticRequest,
  resetHistoryState,
} from '../ducks/History';
import {
  getHistoryTickets,
  getTicketsStatistic,
} from '../ducks/History/selectors';
import { HistoryFormData } from '../types';

export const useHistoryForm = () => {
  const dispatch = useDispatch();

  const tickets = useSelector(getHistoryTickets);
  const ticketsStatistic = useSelector(getTicketsStatistic);
  const isDownload = useSelector(getIsDesktopTicketsLoading);

  const downloadTicketsData = ticketsStatistic?.downloadInfo;

  const { control, watch, resetField, setValue } = useForm<HistoryFormData>({
    mode: 'onChange',
  });

  const { date, organizationId, systemId } = watch();

  useEffect(() => {
    dispatch(fetchOrganizationsRequest());
  }, []);

  useEffect(() => {
    if (organizationId && !Array.isArray(organizationId)) {
      dispatch(fetchSystemsRequest(organizationId?.value));
    }
  }, [organizationId]);

  useEffect(() => {
    if (organizationId || systemId) {
      dispatch(
        setDesktopFilters({
          organizationId: getValueFromValueType(organizationId),
          systemId: getValueFromValueType(systemId),
          to: convertToDateFormat(date),
          from: convertFromDateFormat(date),
          onlyActualTickets: false,
        })
      );
      dispatch(fetchTicketsRequest());
      dispatch(fetchTicketsStatisticRequest());
    }
  }, [date, organizationId, systemId]);

  useEffect(() => {
    return () => {
      dispatch(resetHistoryState());
      dispatch(resetDesktopState());

      resetField('date');
      resetField('organizationId');
      resetField('systemId');
    };
  }, []);

  const handleDownload = useCallback(() => {
    if (downloadTicketsData) {
      dispatch(
        downloadTicketsRequest({
          path: downloadTicketsData?.path,
          fileName: downloadTicketsData?.fileName,
        })
      );
    }
  }, [tickets, downloadTicketsData]);

  return {
    state: {
      tickets,
      ticketsStatistic,
      isDownload,
    },
    methods: {
      historyControl: control,
      handleDownload,
      setHistoryValue: setValue,
    },
  };
};
