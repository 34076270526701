import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getScreenWidth } from 'core/ducks/selectors';
import { ScreenWidth } from 'core/types';
import { useIsAccessEditCategoryOrArticle } from 'features/KnowledgeBase/hooks';
import { checkObjectIdentity } from 'utils';

import { getDataTable } from '../components/KBTableArticles/utils';
import {
  deleteArticleTableRequest,
  fetchArticlesTableCategoryRequest,
  fetchArticlesTableRequest,
  setCurrentArticlesTablePage,
  setCurrentArticleTableId,
  setSizePage,
  setSortArticlesTable,
} from '../ducks/actions';
import {
  getArticlesTable,
  getArticlesTableCategory,
  getArticlesTableFilter,
  getCurrentArticleTable,
  getPropsArticlesTable,
} from '../ducks/selectors';

import { useCategoryId } from './useCategoryId';

export const useKBTableContainer = () => {
  const dispatch = useDispatch();

  const categoryId = useCategoryId();

  const currentCategory = useSelector(getArticlesTableCategory);
  const {
    pageNum,
    pageSize,
    sortArticlesTable,
    totalElements,
    loadingArticlesTable,
  } = useSelector(getPropsArticlesTable);
  const articles = useSelector(getArticlesTable);
  const currentArticleTable = useSelector(getCurrentArticleTable);
  const filterValues = useSelector(getArticlesTableFilter);
  const screenWidth = useSelector(getScreenWidth);

  const [showFilterRow, setShowFilterRow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isFilterActive = !checkObjectIdentity(
    filterValues as Record<string, unknown>,
    {}
  );
  const searchDisabled = !articles?.length || (showFilterRow && isFilterActive);

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const handleSort = (value: string) => {
    if (categoryId) {
      dispatch(setSortArticlesTable(value));
      dispatch(fetchArticlesTableRequest(categoryId));
    }
  };

  const handleRowFullTableClick = (
    value: ReturnType<typeof getDataTable>[number]
  ) => {
    if (currentArticleTable?.id !== value.id) {
      dispatch(setCurrentArticleTableId(value));
      return;
    }
    if (isMobile || isMobileSmall) {
      dispatch(setCurrentArticleTableId(undefined));
    }
  };

  const handleChangePage = (page: number) => {
    if (categoryId) {
      dispatch(setCurrentArticlesTablePage(page));
      dispatch(fetchArticlesTableRequest(categoryId));
    }
  };

  const handleChangePageSize = (arg: number) => {
    dispatch(setSizePage(arg));
  };

  const handleDelete = () => {
    if (currentArticleTable?.id) {
      dispatch(deleteArticleTableRequest(currentArticleTable.id));
    }
  };

  const handleShowModalDelete = () => {
    if (currentArticleTable?.id) {
      setShowDeleteModal(true);
    }
  };

  useEffect(() => {
    if (categoryId) {
      dispatch(fetchArticlesTableCategoryRequest(categoryId));
      dispatch(fetchArticlesTableRequest(categoryId));
    }
  }, [categoryId]);

  const canEditCategory = useIsAccessEditCategoryOrArticle(
    currentCategory?.organizationId,
    currentCategory?.organizationType
  );

  return {
    methods: {
      toggleDeleteModal,
      toggleFilterRow,
      handleSort,
      handleChangePage,
      handleChangePageSize,
      handleDelete,
      handleShowModalDelete,
      onRowClick: handleRowFullTableClick,
    },
    state: {
      pageNum,
      pageSize,
      sortArticlesTable,
      totalElements,
      articles,
      showFilterRow,
      showDeleteModal,
      isFilterActive,
      searchDisabled,
      currentArticleTable,
      tableTitle: currentCategory?.title,
      articleTitle: currentArticleTable?.articleTitle,
      loadingArticlesTable,
      canEditCategory,
    },
  };
};
