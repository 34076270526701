import cn from 'clsx';
import _ from 'lodash';
import {
  ChangeEvent,
  forwardRef,
  Fragment,
  TableHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { Checkbox } from 'components';
import { DataEmpty } from 'core/components';
import { EmptyDataType } from 'core/components/DataEmpty/types';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';

import { Tooltip } from '../Tooltip';

import { TableBody, TableBodyCell, TableHead, TableRow } from './components';
import styles from './Table.module.scss';
import { CheckboxProps, ObjDataType, TableColumns } from './types';
import { getCheckboxList, getTableComponent } from './utils';

export interface TableProps
  extends Omit<TableHTMLAttributes<HTMLTableElement>, 'ref'> {
  columns: TableColumns;
  data: ObjDataType[];
  onSort?: (value: string) => void;
  sort?: string;
  onRowClick?(value?: unknown): void;
  activeRowId?: string;
  filterComponent?: JSX.Element | boolean;
  withHead?: boolean;
  dataLoading?: boolean;
  isFilterActive?: boolean;
  valueFieldName?: string;
  disabledRows?: boolean;
  disabledSort?: boolean;
  checkboxProps?: CheckboxProps;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export const Table = forwardRef<HTMLDivElement, TableProps>((props, ref) => {
  const {
    columns,
    data,
    onSort,
    sort,
    onRowClick,
    activeRowId,
    className,
    filterComponent,
    withHead = true,
    dataLoading,
    isFilterActive,
    valueFieldName,
    disabledRows,
    disabledSort,
    checkboxProps,
  } = props;

  const { withCheckbox, onChangeCheckbox, onSelectAllCheckbox } =
    checkboxProps || {};

  const [checkboxList, setCheckboxList] = useState<Record<string, boolean>>({});
  const isAllChecked =
    !_.isEmpty(checkboxList) &&
    Object.values(checkboxList).every((checkbox) => checkbox);

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isMobileAll = isMobile || isMobileSmall;

  const handleAllChecked = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onSelectAllCheckbox) {
        onSelectAllCheckbox(event);
        setCheckboxList((prev) => {
          return _.reduce(
            prev,
            // eslint-disable-next-line @typescript-eslint/no-shadow
            (acc, _, key) => {
              return {
                ...acc,
                [key]: !isAllChecked,
              };
            },
            {}
          );
        });
      }
    },
    [onSelectAllCheckbox, setCheckboxList, isAllChecked]
  );

  const handleChangeCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { id, checked } = event.target;

      if (onChangeCheckbox) {
        onChangeCheckbox(event);
        setCheckboxList((prev) => {
          const result = { ...prev };
          result[id] = checked;
          return result;
        });
      }
    },
    [onChangeCheckbox, setCheckboxList]
  );

  useEffect(() => {
    if (withCheckbox && _.isEmpty(checkboxList)) {
      setCheckboxList(getCheckboxList(data));
    }
  }, [withCheckbox, data]);

  const headComponent = !!columns.length && (
    <TableHead
      fields={columns}
      sort={sort}
      onSort={onSort}
      disabledSort={disabledSort}
      withCheckbox={withCheckbox}
      checked={isAllChecked}
      handleAllCheckbox={handleAllChecked}
    />
  );

  const tableRowComponent = (rowData: ObjDataType) => {
    const showRowAdaptive = isMobileAll && activeRowId === rowData?.id;
    const isRowActive = activeRowId === rowData?.id;

    const tooltipDeleted = Boolean(rowData?.deleted) && (
      <Tooltip id={rowData?.id?.toString()}>
        {rowData?.tooltipDeletedRow || 'Удалено'}
      </Tooltip>
    );

    const columnsList = columns.map(({ renderType, fieldName }) => {
      if (!renderType) {
        return (
          <TableBodyCell
            key={fieldName}
            className={cn(styles.table__cell, {
              [styles.table__cell_active]: isRowActive,
            })}
          >
            {rowData[fieldName] || ''}
          </TableBodyCell>
        );
      }

      const tableComponent = getTableComponent(renderType)(
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        (rowData[fieldName] as any) || ''
      );

      return (
        <TableBodyCell
          key={fieldName}
          className={cn(styles.table__cell, {
            [styles.table__cell_active]: isRowActive,
          })}
        >
          {tableComponent}
        </TableBodyCell>
      );
    });

    const tableRow = (
      <TableRow
        data-tip
        data-for={rowData?.id}
        onClick={onRowClick}
        value={valueFieldName ? rowData[valueFieldName] : rowData}
        isActive={isRowActive}
        disabled={disabledRows}
        deleted={Boolean(rowData?.deleted)}
        className={cn({
          [styles.table__row]: !isRowActive,
        })}
      >
        {withCheckbox && (
          <Checkbox
            onChange={handleChangeCheckbox}
            id={rowData?.id?.toString() || ''}
            className={styles.table__checkbox}
            checked={checkboxList[rowData?.id?.toString() || '']}
          />
        )}

        {columnsList}
        <TableBodyCell>
          <div />
        </TableBodyCell>
      </TableRow>
    );

    return (
      <Fragment key={rowData?.id?.toString()}>
        {tableRow}
        {tooltipDeleted}
        {showRowAdaptive && rowData?.adaptiveRow}
      </Fragment>
    );
  };

  const dataTable = (
    <TableBody>{data.map((dataRow) => tableRowComponent(dataRow))}</TableBody>
  );

  const emptyDataType = isFilterActive
    ? EmptyDataType.NO_DATA_WITH_FILTER
    : EmptyDataType.NO_DATA;

  const emptyData = !data.length && !dataLoading && (
    <DataEmpty type={emptyDataType} />
  );

  return (
    <div className={cn(styles.table, className)} ref={ref}>
      <div className={styles.table__wrapper}>
        {withHead && headComponent}
        {filterComponent}
        {Boolean(data.length) && dataTable}
      </div>
      {emptyData}
    </div>
  );
});
