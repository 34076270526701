import { isString } from 'lodash';

import { CustomFieldType, getReverseDateFromCustomField } from 'components';
import { CustomFieldToRequest } from 'core/types';

import { FIELD_NAMES_BY_CUSTOM_FIELD_TYPE } from '../constants';
import { AbstractDataType } from '../types';

export const getFieldNameByType = (type: CustomFieldType) => {
  return FIELD_NAMES_BY_CUSTOM_FIELD_TYPE[type] as keyof CustomFieldToRequest;
};

const getPropsCustomFieldType = (field: CustomFieldToRequest) => {
  if (!field.customFieldType) {
    return null;
  }

  const fieldTypeMap = {
    [CustomFieldType.FIELD_DATE]: () => field.date,
    [CustomFieldType.FIELD_FLAG]: () => field.flag,
    [CustomFieldType.FIELD_TEXT]: () => field.text,
  };

  return fieldTypeMap[field.customFieldType]();
};

const getFieldValueByFieldType = (customField: CustomFieldToRequest) => {
  if (!customField?.customFieldType) {
    return '';
  }

  const isDate = customField?.customFieldType === CustomFieldType.FIELD_DATE;
  const value = getPropsCustomFieldType(customField);

  return isDate && isString(value)
    ? getReverseDateFromCustomField(value)
    : value;
};

export const convertCustomFieldsFromArrToObject = (
  customFieldsArr: CustomFieldToRequest[]
) => {
  const result: AbstractDataType = {};
  customFieldsArr.forEach((item: CustomFieldToRequest) => {
    if (item.id && item?.customFieldType) {
      result[item.id] = getFieldValueByFieldType(item);
    }
  });
  return result;
};
