import { createSelector } from 'reselect';

import { SelectOption, TabItem } from 'components';
import { Environment } from 'core/types';
import { System, SystemPriority } from 'core/types/system';
import { DEFAULT_RADIO_TABS } from 'features/CreateTicket';
import { RootState } from 'store/rootReducer';

export const getSystems = (state: RootState): System[] | undefined =>
  state.systems.systems;
export const getSystemsTickets = (state: RootState): System[] | undefined =>
  state.systems.systemsTickets;
export const getSystemsOrganization = (
  state: RootState
): System[] | undefined => state.systems.systemsOrganization;
export const getLoading = (state: RootState): boolean | undefined =>
  state.systems.loading;
export const getPropsSystems = (state: RootState) => ({
  pageNum: state.systems.pagination?.pageNum,
  pageSize: state.systems.pagination?.pageSize,
  sort: state.systems.sort,
  totalElements: state.systems.pagination?.totalElements,
  loadingSystem: getLoading(state),
});
export const getPropsSystemsTicket = (state: RootState) => ({
  pageNum: state.systems.paginationTicketsFilter?.pageNum,
  pageSize: state.systems.paginationTicketsFilter?.pageSize,
  sort: state.systems.sortSystemTickets,
  totalElements: state.systems.paginationTicketsFilter?.totalElements,
});
export const getSort = (state: RootState) => state.systems.sort;
export const getMySystems = (state: RootState) => state.systems.mySystems;
export const getSystemByContract = (state: RootState) =>
  state.systems.systemByContract;
export const getSystemId = (state: RootState) => state.systems.systemId;
export const getOrganizationSystemId = (state: RootState) =>
  state.systems.organizationSystemId;
export const getSystemsFilter = (state: RootState) =>
  state.systems.systemsFilter || {};
export const getSelectedSystem = (state: RootState) =>
  state.systems.selectedSystem;
export const getSystemPriorities = (state: RootState) =>
  state.systems.systemPriorities;
export const getSLASystems = (state: RootState) => state.systems.sla;
export const getEnvironments = (state: RootState) => state.systems.environments;

export const getTicketSystemsSelectList = createSelector<
  RootState,
  System[] | undefined,
  SelectOption[]
>([getSystemsTickets], (systemsTickets): SelectOption[] => {
  if (systemsTickets) {
    return systemsTickets.map(
      (system: System): SelectOption => ({
        title: system.title,
        value: system.id,
      })
    );
  }
  return [];
});

export const getSystemsSelectList = createSelector<
  RootState,
  System[] | undefined,
  SelectOption[]
>([getSystems], (systems): SelectOption[] => {
  if (systems) {
    return systems.map(
      (system: System): SelectOption => ({
        title: system.title,
        value: system.id,
      })
    );
  }
  return [];
});

export const getMySystemsSelectList = createSelector<
  RootState,
  System[] | undefined,
  SelectOption[]
>([getMySystems], (mySystems): SelectOption[] => {
  if (mySystems) {
    return mySystems?.map(
      (system: System): SelectOption => ({
        title: system.title,
        value: system.id,
      })
    );
  }
  return [];
});

export const getPriorityByTitleRadioTabs = createSelector<
  RootState,
  SystemPriority[] | undefined,
  TabItem[]
>([getSystemPriorities], (priorities): TabItem[] => {
  return DEFAULT_RADIO_TABS.map((tab) => {
    if (priorities?.find((priority) => priority.value === tab.value)) {
      return { ...tab, title: tab.value };
    }
    return {
      ...tab,
      title: tab.value,
      disabled: true,
    };
  });
});

export const getIsErrorNoData = (state: RootState) =>
  state.systems.isErrorNoData;
export const getSystem = (state: RootState) => state.systems.system;

export const getSystemsOrganizationSelectList = createSelector<
  RootState,
  System[] | undefined,
  SelectOption[]
>([getSystemsOrganization], (systemsOrganization): SelectOption[] => {
  if (systemsOrganization) {
    return systemsOrganization.map(
      (system: System): SelectOption => ({
        title: system.title,
        value: system.id,
      })
    );
  }
  return [];
});

export const getEnvironmentsOptions = createSelector<
  RootState,
  Environment[] | undefined,
  SelectOption[]
>([getEnvironments], (environments): SelectOption[] => {
  return (
    environments?.map((environment) => ({
      title: environment.name,
      value: environment.id,
    })) || []
  );
});
