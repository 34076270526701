import { ReactNode } from 'react';

export type ButtonType = 'default' | 'flat' | 'outline';

export type TabItem<Value = string> = {
  title?: string;
  value: Value;
  checked?: boolean;
  defaultChecked?: boolean;
  classNameTab?: string;
  disabled?: boolean;
  onClick?: () => void;
  icon?: ReactNode;
  readOnly?: boolean;
};

export enum Size {
  xxs = 'xxs',
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
}

export type Orientation = 'vertical' | 'horizontal';

export enum CustomFieldType {
  FIELD_TEXT = 'TEXT',
  FIELD_DATE = 'DATE',
  FIELD_FLAG = 'FLAG',
}
