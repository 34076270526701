import { useEffect, useRef, useState } from 'react';
import useWebSocket from 'react-use-websocket';

import { getToken } from 'utils/authService';

import { getSocketUrl } from '../utils';

import { useTicketId } from './useTicketId';

export const useTicketCommentsWebSocket = () => {
  const ticketId = useTicketId();

  const [socketUrl] = useState(getSocketUrl(ticketId));
  const didUnmount = useRef(false);

  const { sendMessage, lastJsonMessage } = useWebSocket(socketUrl, {
    protocols: ['access_token', getToken() || ''],
    shouldReconnect: () => {
      return !didUnmount.current;
    },
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    share: true,
  });

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  return {
    state: {
      lastJsonMessage,
    },
    methods: {
      sendMessage,
    },
  };
};
