import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { setAlert } from 'core/ducks/actions';
import { getErrorAlert, getSuccessAlert } from 'core/layouts';
import { ActionForAlertTypes, Environment } from 'core/types';
import { SLAType } from 'core/types/sla';
import { System, SystemPriority } from 'core/types/system';
import { getFilterSystemToRequest } from 'core/utils';
import { getCurrentContractId, resetContractsState } from 'features/Contracts';
import { ResponseWithMeta } from 'store/types';
import { createError, getMockMeta } from 'utils';

import {
  fetchCurrentSystemRequest,
  fetchEnvironmentsSuccess,
  fetchFiltersSystemsRequest,
  fetchFiltersSystemsSuccessJoin,
  fetchFiltersSystemsSuccessUpdate,
  fetchMySystemsRequest,
  fetchSLAByContractIdBySystemIdSuccessSystems,
  fetchSystemPrioritiesSuccess,
  fetchSystemsOrganizationSuccess,
  fetchSystemsSuccessJoin,
  fetchSystemsSuccessUpdate,
  hideSystemsLoading,
  resetCurrentSystem,
  setCurrentSystemSuccess,
  showSystemsLoading,
} from './actions';
import { request } from './api/requests';
import { getPropsSystems, getSystemsFilter } from './selectors';
import {
  DeleteSystemRequestAction,
  FetchSLAByContractIdBySystemIdRequestSystemsAction,
  FetchSystemPrioritiesRequestAction,
  FetchSystemsOrganizationForJiraRequestAction,
  FetchSystemsOrganizationRequestAction,
  Systems,
} from './types';

const ENTITY_SYSTEM = 'Система';

function* systemsFetch() {
  try {
    const { pageNum, pageSize, sort }: ReturnType<typeof getPropsSystems> =
      yield select(getPropsSystems);
    const filter: ReturnType<typeof getSystemsFilter> = yield select(
      getSystemsFilter
    );

    yield put(showSystemsLoading());
    const systems: ResponseWithMeta<System[]> = yield call(
      request.fetchSystems,
      pageNum,
      pageSize,
      sort,
      getFilterSystemToRequest(filter)
    );
    yield put(fetchSystemsSuccessUpdate(systems));
    yield put(hideSystemsLoading());
  } catch (e) {
    createError(e);
    yield put(hideSystemsLoading());
  }
}

function* systemsOrganizationFetch({
  payload,
}: FetchSystemsOrganizationRequestAction) {
  try {
    const systemsOrganization: System[] = yield call(
      request.fetchSystemsOrganization,
      payload
    );

    yield put(fetchSystemsOrganizationSuccess(systemsOrganization));
  } catch (e) {
    createError(e);
  }
}

function* currentSystemFetch({
  payload,
}: ReturnType<typeof fetchCurrentSystemRequest>) {
  try {
    const system: System = yield call(request.fetchCurrentSystem, payload);
    yield put(setCurrentSystemSuccess(system));
  } catch (e) {
    createError(e);
  }
}

function* systemsFetchByContractId() {
  try {
    const { pageNum, pageSize, sort }: ReturnType<typeof getPropsSystems> =
      yield select(getPropsSystems);
    const filter: ReturnType<typeof getSystemsFilter> = yield select(
      getSystemsFilter
    );
    const contractId: ReturnType<typeof getCurrentContractId> = yield select(
      getCurrentContractId
    );
    if (contractId) {
      yield put(showSystemsLoading());
      const systemsByContract: ResponseWithMeta<System[]> = yield call(
        request.fetchSystemsByContractId,
        contractId,
        pageNum,
        pageSize,
        sort,
        getFilterSystemToRequest({
          ...filter,
          deleted: null,
        })
      );
      yield put(fetchSystemsSuccessUpdate(systemsByContract));
    }

    yield put(hideSystemsLoading());
  } catch (e) {
    createError(e);
    yield put(hideSystemsLoading());
  }
}

function* systemsWithContractsFetch() {
  try {
    yield put(showSystemsLoading());
    const systems: System[] = yield call(request.fetchSystemsWithContracts);
    const systemsWithMeta = getMockMeta<System[]>(systems);
    yield put(fetchSystemsSuccessUpdate(systemsWithMeta));
    yield put(hideSystemsLoading());
  } catch (e) {
    createError(e);
    yield put(hideSystemsLoading());
  }
}

function* mySystemsFetch({
  payload,
}: ReturnType<typeof fetchMySystemsRequest>) {
  try {
    const { pageNum, pageSize, sort } = yield select(getPropsSystems);
    const filter: ReturnType<typeof getSystemsFilter> = yield select(
      getSystemsFilter
    );
    const systems = (yield call(
      request.fetchMySystems,
      pageNum,
      pageSize,
      sort,
      getFilterSystemToRequest(filter)
    )) as ResponseWithMeta<System[]>;
    yield put(
      payload === 'join'
        ? fetchSystemsSuccessJoin(systems)
        : fetchSystemsSuccessUpdate(systems)
    );
  } catch (e) {
    createError(e);
  }
}

function* filtersSystemsFetch({
  payload,
}: ReturnType<typeof fetchFiltersSystemsRequest>) {
  try {
    const { pageNum, pageSize, updateType, searchValue } = payload;
    const systems = (yield call(
      request.fetchFiltersSystems,
      pageNum,
      pageSize,
      'TITLE_ASC',
      { title: searchValue }
    )) as ResponseWithMeta<System[]>;
    yield put(
      updateType === 'join'
        ? fetchFiltersSystemsSuccessJoin(systems)
        : fetchFiltersSystemsSuccessUpdate(systems)
    );
  } catch (e) {
    createError(e);
  }
}

function* systemPrioritiesFetch({
  payload,
}: FetchSystemPrioritiesRequestAction) {
  try {
    const priorities: SystemPriority[] = yield call(
      request.fetchSystemPriorities,
      payload
    );
    yield put(fetchSystemPrioritiesSuccess(priorities));
  } catch (e) {
    yield put(fetchSystemPrioritiesSuccess([]));
    createError(e);
  }
}

function* deleteSystem({ payload }: DeleteSystemRequestAction) {
  try {
    yield call(request.deleteSystem, payload);
    yield put(
      setAlert(getSuccessAlert(ENTITY_SYSTEM, ActionForAlertTypes.DELETE))
    );
    yield put(resetCurrentSystem());
    yield put(resetContractsState());
    yield call(systemsFetch);
  } catch (e) {
    yield put(
      setAlert(getErrorAlert(ENTITY_SYSTEM, ActionForAlertTypes.DELETE))
    );
    createError(e);
  }
}

function* systemsOrganizationForJiraFetch({
  payload,
}: FetchSystemsOrganizationForJiraRequestAction) {
  try {
    const systems: System[] = yield call(
      request.fetchSystemsOrganizationForJira,
      payload
    );

    yield put(fetchSystemsOrganizationSuccess(systems));
  } catch (e) {
    createError(e);
  }
}

function* fetchSLA({
  payload,
}: FetchSLAByContractIdBySystemIdRequestSystemsAction) {
  try {
    const { contractId, systemId } = payload;
    if (contractId && systemId) {
      const sla: SLAType = yield call(request.fetchSLA, contractId, systemId);
      yield put(fetchSLAByContractIdBySystemIdSuccessSystems(sla));
    }
  } catch (e) {
    createError(e);
  }
}

function* fetchEnvironments() {
  try {
    const filter: ReturnType<typeof getSystemsFilter> = yield select(
      getSystemsFilter
    );
    const environments: ResponseWithMeta<Environment[]> = yield call(
      request.fetchEnvironments,
      filter
    );
    yield put(fetchEnvironmentsSuccess(environments));
  } catch (e) {
    createError(e);
  }
}

export function* systemsSaga(): Generator<StrictEffect> {
  yield takeEvery(Systems.FETCH_SYSTEMS_REQUEST, systemsFetch);
  yield takeEvery(
    Systems.FETCH_SYSTEMS_ORGANIZATION_REQUEST,
    systemsOrganizationFetch
  );

  yield takeEvery(Systems.FETCH_CURRENT_SYSTEM_REQUEST, currentSystemFetch);
  yield takeEvery(
    Systems.FETCH_SYSTEMS_BY_CONTRACT_ID_REQUEST,
    systemsFetchByContractId
  );
  yield takeEvery(
    Systems.FETCH_SYSTEMS_WITH_CONTRACTS_REQUEST,
    systemsWithContractsFetch
  );
  yield takeEvery(Systems.FETCH_MY_SYSTEMS_REQUEST, mySystemsFetch);
  yield takeEvery(Systems.FETCH_FILTERS_SYSTEMS_REQUEST, filtersSystemsFetch);
  yield takeEvery(
    Systems.FETCH_SYSTEM_PRIORITIES_REQUEST,
    systemPrioritiesFetch
  );
  yield takeEvery(Systems.DELETE_SYSTEM_BY_ID_REQUEST, deleteSystem);
  yield takeEvery(
    Systems.FETCH_SYSTEMS_ORGANIZATION_FOR_JIRA_REQUEST,
    systemsOrganizationForJiraFetch
  );
  yield takeEvery(
    Systems.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
    fetchSLA
  );
  yield takeEvery(Systems.FETCH_ENVIRONMENTS_REQUEST, fetchEnvironments);
}
