import { MouseEvent } from 'react';

import { SelectOption, Size, ValueType } from 'components';
import { OrganizationType, Status } from 'core/types';
import { AttachmentDeleteType, Ticket, TicketSLAType } from 'core/types/ticket';
import { User } from 'core/types/user';
import { Attachment, AttachmentsFiles } from 'types/models/attachment';
import { KeyValueOption } from 'types/models/meta';

export type Solution = {
  attachments?: string[];
  solutionId?: string;
  textSolution?: string;
};

export type TicketSLA = {
  decisionDate: string;
  maxDecisionDate: string;
  reactionDate: string;
  maxReactionDate: string;
  onPause: boolean;
  roundTheClock: boolean;
  slaType: TicketSLAType;
  to: string;
  from: string;
  expiredSeconds?: number;
  reactionExpireSeconds?: number;
  maxDecisionSeconds?: number;
  maxReactionSeconds?: number;
  secondsAfterTimerRefresh?: number;
  secondsOnTimer?: number;
  nowIsWorkTime?: boolean;
  expired?: boolean;
};

export type TicketClient = {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  middleName: string;
  organizationType: OrganizationType;
  phoneNumber: string;
  organizationTitle: string;
  organizationId: string;
};

export type TicketUpdateType = Omit<
  Partial<Ticket>,
  'environmentId' | 'systemId' | 'tags'
> & {
  environmentId: string;
  systemId: string;
  tags: string[];
};

export enum HistoryType {
  CHANGE_STATUS = 'Изменение статуса',
  CREATE = 'Тикет создан',
  APPOINT = 'Назначен исполнитель',
}

export enum MessageType {
  CREATE = 'Тикет создан',
  APPOINT = 'Назначен исполнитель',
  IN_WORK = 'В работе',
  WAITING_TO_CLOSE = 'В ожидании закрытия',
  WAITING_INFO = 'В ожидании информации',
  CLOSED = 'Закрыт',
  REOPEN = 'Переоткрыт',
}

export type TicketHistory = {
  id: string;
  ticketId: string;
  date: string;
  message: MessageType;
  type: HistoryType;
  personTriggerId: string;
  personTriggeredName: string;
  statusInfo: Status;
};

export interface MessageData {
  commentType: CommentType;
  message?: string;
  ticketsIds?: ValueType<string>;
}

export interface CancelMessageData {
  cancelMessageText: string;
}

export type ModeTab = 'history' | 'notes';

export type TicketTab = {
  title: string | JSX.Element;
  onTabClick: () => void;
  className?: string;
  isTabActive?: boolean;
};

export enum CommentType {
  CLOSE_REQUEST = 'CLOSE_REQUEST',
  INFO_REQUEST = 'INFO_REQUEST',
  RETURN_TO_WORK = 'RETURN_TO_WORK',
  SOLUTION = 'SOLUTION',
  USUAL = 'USUAL',
  CHANGE_STATUS = 'CHANGE_STATUS',
  REOPEN = 'REOPEN',
}

export type CommentFile = KeyValueOption;

export type CommentSpoiler = {
  header?: string;
  text?: string;
  titleSpoiler?: string;
};

export type Comment = {
  id?: string;
  isClient?: boolean;
  userDto?: User;
  ticketId: string;
  message?: string;
  created?: string;
  updated?: string;
  type: CommentType;
  pathsToFiles?: CommentFile[];
  cancelText?: string;
  solutionAccepted?: boolean;
  spoiler?: CommentSpoiler;
  requestCommentId?: string;
  requestCommentText?: string;
  solutionAcceptedBySystem?: boolean;
  nextStatus?: Status;
  mentionedTickets?: KeyValueOption[];
  files?: AttachmentsFiles[];
};

export type TicketAttachmentFile = {
  lastName: string;
  firstName: string;
  path: string;
  fileName: string;
  created: string;
};

export type Note = {
  id?: string;
  text?: string;
  ticketId?: string;
  userId?: string;
  created?: Date;
  userFirstName?: string;
  userLastName?: string;
  visibility?: VisibilityType;
  files?: AttachmentsFiles[];
};

export type CreateNoteData = {
  note: Note;
  attachmentsData?: Omit<Attachment, 'id' | 'attachmentsFiles'> | null;
  files?: FormData | null;
};

export type Tag = {
  id: string;
  name?: string;
};

export type TagsFilter = {
  name?: string;
  systemIds?: string[];
};

export type CreateTagData = {
  systemId?: string;
} & Pick<Tag, 'name'>;

export type Improvement = {
  id: string;
  value: string;
};

export type TicketEvaluation = {
  needRating: boolean;
  rating: number;
  ratings: Improvement[];
  checkedRatings: string[];
};

export interface CloseTicketRequest {
  id?: string;
  reasonClosing?: string;
}

export type AttachmentsRequest = {
  attachment: FormData;
  id: string;
};

export type NextStatusesRequest = {
  currentStatusId: string;
  ticketCreatorId: string;
};

export type CreateNote = {
  text: string;
  visibility: SelectOption<VisibilityType>;
};

export enum VisibilityType {
  PRIVATE = 'PRIVATE',
  WORKGROUPS = 'WORKGROUPS',
}

export interface DeleteFileAttachmentsTicketRequest {
  attachmentFileId?: string;
  attachmentId?: string;
  ticketId?: string;
  fileName?: string;
  attachmentDeleteType?: AttachmentDeleteType;
}

export interface CreateTicketInJiraPayload {
  ticketId: string;
  systemId: string;
  ticketTypeId: string;
  userId: string;
}

export type CheckExistIntegration = {
  systemId: string;
  typeId: string;
};

export interface TicketFileType {
  e: MouseEvent;
  path?: string;
  fileName: string;
  attachmentId?: string;
  attachmentFileId?: string;
}

export interface PreviewAttachmentType {
  index?: number;
  handleAttachment?: (index: number | undefined) => void;
  handleDeleteFile({
    e,
    attachmentId,
    attachmentFileId,
    fileName,
  }: TicketFileType): void;
  handleDownloadFile({ e, path, fileName }: TicketFileType): void;
  size?: Size;
  isButtonDelete?: boolean;
  files?: AttachmentsFiles[];
  attachment?: Attachment;
}

export interface ReopenTicket {
  ticketId: string;
  reasonReopen?: string;
}

export type EditTicketTypeFormValues = {
  typeId: string;
};

export type CheckIsExistTagArgs = {
  systemId: string;
  name: string;
};
