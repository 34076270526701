import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';
import { TicketStatusesRequest } from 'core/types';
import { ResponseWithMeta } from 'store/types';
import { KeyValueOption, Pagination } from 'types/models/meta';

import {
  INITIAL_ATTRIBUTES_VALUES,
  INITIAL_TRIGGERS_FILTER,
} from '../constants';
import {
  CreateTrigger,
  CustomFields,
  FiltersFields,
  Trigger,
  TriggerAttributes,
  TriggersAttributeToRequest,
  TriggersFilter,
} from '../types';

export interface TriggersReducerState {
  triggers?: Trigger[];
  trigger?: Trigger;
  loading: boolean;
  pagination: Pagination;
  sort: string;
  triggerId?: string;
  filter: TriggersFilter;
  attributesFields: TriggerAttributes;
  filtersFields?: FiltersFields;
  customFields?: CustomFields[];
  nextTicketStatus?: KeyValueOption[];
}

const initialState: TriggersReducerState = {
  triggers: [],
  loading: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  sort: 'CREATED_AT_DESC',
  filter: INITIAL_TRIGGERS_FILTER,
  attributesFields: INITIAL_ATTRIBUTES_VALUES,
};

const triggersSlice = createSlice({
  name: 'triggers',
  initialState,
  reducers: {
    showTriggersLoading: (state) => {
      state.loading = true;
    },
    hideTriggersLoading: (state) => {
      state.loading = false;
    },
    fetchFiltersFieldsRequest: () => {},
    fetchFiltersFieldsSuccess: (
      state,
      action: PayloadAction<FiltersFields>
    ) => {
      state.filtersFields = action.payload;
    },
    fetchAttributesFieldsRequest: (
      _state,
      _action: PayloadAction<TriggersAttributeToRequest>
    ) => {},
    fetchAttributesFieldsSuccess: (
      state,
      action: PayloadAction<TriggerAttributes>
    ) => {
      state.attributesFields = {
        ...state.attributesFields,
        ...action.payload,
      };
    },
    fetchTicketStatusRequest: (
      _state,
      _action: PayloadAction<TicketStatusesRequest>
    ) => {},
    fetchTicketStatusSuccess: (
      state,
      action: PayloadAction<KeyValueOption[]>
    ) => {
      state.attributesFields.status = action.payload;
    },
    fetchNextTicketStatusRequest: (
      _state,
      _action: PayloadAction<TicketStatusesRequest>
    ) => {},
    fetchNextTicketStatusSuccess: (
      state,
      action: PayloadAction<KeyValueOption[]>
    ) => {
      state.nextTicketStatus = action.payload;
    },

    fetchTriggersSuccess: (
      state,
      action: PayloadAction<ResponseWithMeta<Trigger[]>>
    ) => {
      const { content, page, totalElements } = action.payload;

      state.triggers = content;
      state.pagination.pageNum = page;
      state.pagination.totalElements = totalElements;
    },
    setCurrentTriggersPage: (state, action: PayloadAction<number>) => {
      state.pagination.pageNum = action.payload;
    },
    setSizePage: (state, action: PayloadAction<number>) => {
      state.pagination.pageSize = action.payload;
    },
    setSortTriggers: (state, action: PayloadAction<string>) => {
      state.sort = action.payload;
    },
    fetchCurrentTrigger: (_state, _action: PayloadAction<string>) => {},
    setCurrentTriggerSuccess: (
      state,
      action: PayloadAction<Trigger | undefined>
    ) => {
      state.trigger = action.payload;
    },
    fetchCustomFieldsRequest: (_state, _action: PayloadAction<string>) => {},
    fetchCustomFieldsSuccess: (
      state,
      action: PayloadAction<CustomFields[]>
    ) => {
      state.customFields = action.payload;
    },
    createTrigger: (_state, _action: PayloadAction<CreateTrigger>) => {},
    updateTrigger: (_state, _action: PayloadAction<CreateTrigger>) => {},
    setCurrentTriggerId: (state, action: PayloadAction<string | undefined>) => {
      state.triggerId = action.payload;
    },
    setTriggersFilter: (state, action: PayloadAction<TriggersFilter>) => {
      state.filter = action.payload;
    },
    fetchTriggersRequest: () => {},
    deleteTriggerRequest: (_state, _action: PayloadAction<string>) => {},
    resetCurrentTrigger: (state) => {
      state.trigger = initialState.trigger;
      state.triggerId = initialState.triggerId;
    },
    resetTriggersState: () => initialState,
  },
});

export const {
  showTriggersLoading,
  hideTriggersLoading,
  fetchFiltersFieldsRequest,
  fetchFiltersFieldsSuccess,
  fetchAttributesFieldsRequest,
  fetchAttributesFieldsSuccess,
  fetchTicketStatusRequest,
  fetchTicketStatusSuccess,
  fetchNextTicketStatusRequest,
  fetchNextTicketStatusSuccess,
  updateTrigger,
  fetchTriggersSuccess,
  setSizePage,
  setSortTriggers,
  fetchCurrentTrigger,
  setCurrentTriggerSuccess,
  fetchCustomFieldsRequest,
  fetchCustomFieldsSuccess,
  createTrigger,
  setCurrentTriggerId,
  setTriggersFilter,
  resetTriggersState,
  fetchTriggersRequest,
  deleteTriggerRequest,
  resetCurrentTrigger,
  setCurrentTriggersPage,
} = triggersSlice.actions;
export const triggers = triggersSlice.reducer;
