import { Article } from 'core/types/knowledgeBase';
import { Organization } from 'core/types/organization';
import {
  CategoryArticle,
  KBOrganizationsFilter,
} from 'features/KnowledgeBase/types';
import { ResponseWithMeta } from 'store/types';
import { ApiClient, getEnv } from 'utils';

import { KBArticleTableFilterToRequest } from '../../../../types';

import { endpoints } from './endpoints';

export const request = {
  fetchArticles: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: KBArticleTableFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Article[]>>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getArticlesFilter(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {
        ...filter,
      },
    }),
  fetchOrganizations: (filter?: KBOrganizationsFilter) =>
    ApiClient.post<Organization[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getOrganizations(),
      data: filter,
    }),
  fetchCategoriesRequest: (organizationId?: string) =>
    ApiClient.get<CategoryArticle[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.fetchCategories(organizationId),
    }),
};
