import { Article } from 'core/types/knowledgeBase';
import {
  ArticleCreateData,
  CategoryArticle,
} from 'features/KnowledgeBase/types';
import { ApiClient, getEnv } from 'utils';

import { endpoints } from './endpoints';

export const request = {
  createCategoryRequest: (data: Partial<CategoryArticle>) =>
    ApiClient.post<CategoryArticle>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getCategoryCreate(),
      data: { ...data },
    }),
  editCategoryRequest: (data: Partial<CategoryArticle>) =>
    ApiClient.patch<CategoryArticle>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.changeCategory(),
      data: { ...data },
    }),
  deleteCategoryRequest: (id?: string) =>
    ApiClient.delete({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.changeCategory(),
      data: id,
    }),
  createArticleRequest: (data: ArticleCreateData) =>
    ApiClient.post<Article>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getArticleCreate(),
      data: { ...data },
    }),
  editArticleRequest: (data: Partial<Article>) =>
    ApiClient.patch<Article>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getArticleEdit(),
      data: { ...data },
    }),
  deleteArticleRequest: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getArticleDelete(id),
    }),
  fetchCategoryRequest: (id: string) =>
    ApiClient.get<CategoryArticle>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getCategory(id),
    }),
  fetchArticleRequest: (id: string) =>
    ApiClient.get<Article>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getArticle(id),
    }),
};
