import { FC } from 'react';
import { useSelector } from 'react-redux';

import { MoreTag, Table } from 'components';
import { System, TableSystemsTypes } from 'core/types/system';
import { getLoading as getContractsLoading } from 'features/Contracts';
import { KeyValueOption } from 'types/models/meta';

import { TABLE_HEADER_FULL_FIELDS } from '../../constants';
import { getLoading } from '../../ducks/selectors';
import { SystemsFilter } from '../SystemsFilter';

const getMoreTag = (data: KeyValueOption[]) => {
  return (!!data?.length && <MoreTag data={data} />) || '-';
};

const getDataTable = (systems: System[]) =>
  systems?.map((system) => {
    const {
      title,
      description,
      id,
      versionTitle,
      organization,
      activeContract,
    } = system;

    const typeList = system?.typeList.map((item) => ({
      key: item.id,
      value: item.title,
    }));

    const environmentList = system?.environmentList.map((item) => ({
      key: item.id,
      value: item.name,
    }));

    return {
      id,
      title: {
        title,
        to: `/admin/systems/${id}`,
      },
      description,
      versionTitle: { text: versionTitle },
      organizationId: {
        title: organization?.title,
        to: `/admin/organizations/${organization?.id}`,
      },
      activeContract: activeContract?.title,
      ticketType: getMoreTag(typeList),
      environment: getMoreTag(environmentList),
    };
  }) || [];

interface SystemsFullTableProps {
  systems?: System[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleClickSystem?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
}

export const SystemsFullTable: FC<SystemsFullTableProps> = ({
  systems = [],
  sort,
  handleSort,
  handleClickSystem,
  activeRowId,
  showFilterRow,
  isFilterActive,
}) => {
  const data = getDataTable(systems);

  const loading = useSelector(getLoading);
  const contractsLoading = useSelector(getContractsLoading);

  return (
    <Table
      data={data}
      columns={TABLE_HEADER_FULL_FIELDS}
      sort={sort}
      onRowClick={handleClickSystem}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && <SystemsFilter tableType={TableSystemsTypes.FULL} />
      }
      disabledSort={loading}
      disabledRows={contractsLoading}
    />
  );
};
